import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { CURRENCY } from '../../content/currency';
import { getAccessToken } from '../helpers';


const OrderHistoryRow = React.memo(({ SKU, orderId, createdTime, price }) => {

    const ISO_CODE = process.env.REACT_APP_ISO_CODE;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE.toUpperCase();

    const filename = `${LANGUAGE}_${SKU}.png`
    let filepath = `/img/products/${ISO_CODE}/small/${filename}`

    return (
        <div className='order row'>
            <div className="leftInfo row">
                <img src={filepath} alt={SKU} />
                <div className="title">{SKU}</div>
            </div>
            <div className="rightInfo column">
                <div><span>Order ID:</span> {orderId}</div>
                <div><span>Order Placed:</span> {createdTime}</div>
                <div className='price'><span>Total:</span> {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{price}</div>
            </div>
        </div>
    );
})

const OrderHistory = React.memo(() => {

    const [orderHistory, setOrderHistory] = useState(false);

    const uid = useSelector((state) => state.user.uid)

    useEffect(() => {

        if (!uid) {
            return
        }

        getAccessToken().then(token => {

            return fetch(process.env.REACT_APP_URL_ORDER_HISTORY, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken: token }),
            })
                .then(response => response.json())
                .then(resp => {

                    const orderHistory = resp?.orderHistory;

                    if (orderHistory) {
                        setOrderHistory(orderHistory)
                    }

                }).catch(e => {
                    console.log(e)
                })

        })

    }, [uid, setOrderHistory]);

    return (
        <div className='orderHistory column'>
            {orderHistory ?
                orderHistory.map((order, i) => <OrderHistoryRow {...order} key={i} />)
                :
                <div className='noHistory'>{DEFAULT_TEXT['page.myaccount.tab.orderHistory.noHistory']}</div>
            }
        </div>
    );
})

export default OrderHistory;