import React from 'react';
import Footer from './Footer';
import Header from './header/Header';
import MenuCover from './header/MenuCover';

const AllWrap = React.memo(({ children, helpPage, auctionPage, soldPage }) => {

    return (
        <>
            <div className="allBox column">
                <Header />

                {children}

                <Footer helpPage={helpPage} auctionPage={auctionPage} soldPage={soldPage} />
                <MenuCover />
            </div>
        </>
    );
})

export default AllWrap;