import { getAuth } from "firebase/auth";
import { DEFAULT_TEXT } from '../../content';
import { helpTitles, helpTopics } from '../../content/help';

export const getParams = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const redirect_status = urlParams.get('redirect_status')
    let bidPackage = urlParams.get('bidPackage')

    if (bidPackage) {
        bidPackage = parseInt(bidPackage)
    }

    return { redirect_status, bidPackage }

}

export const getAccessToken = () => {

    // RETURNS ACCESS TOKEN HELPER

    const auth = getAuth();

    return auth.currentUser.getIdToken(true)

}

export const displayNameRules = value => {

    let error = false;

    if (!value?.length) {
        error = DEFAULT_TEXT['form.displayName.error.required']
    } else if (value.length < 3) {
        error = DEFAULT_TEXT['form.displayName.error.minimum']
    }

    return error

}

export const createHelpGroups = () => {

    // GROUPS OF HELP TOPICS WILL BE CREATED
    let groups = {

    }

    helpTopics.forEach(x => {

        const group = x?.group || 'default';

        if (!groups[group]) {
            groups[group] = {}
            groups[group].title = helpTitles[group];
            groups[group].topics = [];
        }

        groups[group].topics.push(x)

    })


    return groups
}

export const createHelpTitleId = title => {

    return title.replace(/ /g, '').replace(/[^a-zA-Z ]/g, "").toLowerCase()

}

export const createPrice = price => {

    price = parseInt(price)

    price = (price / 100).toFixed(2);

    return price
}

export const scrollTop = () => {

    window.scrollTo(0, 0);

}

export const currentPage = searchParams => parseInt(searchParams.get("page")) || 1;