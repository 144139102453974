import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { useCartMode } from '../../providers/CartMode';
import { ShippingBox } from '../../style/main';
import { getAccessToken } from '../helpers';
import LdsRing from '../snippet/LdsRing';
import { MemberPopContext } from '../MemberPop';
import { actionMemberPop } from '../../reducers/user';
import { actionShoppingFromCity } from '../../reducers/layout';
import { localStorageSetCity } from '../helpers/localstorage';
import { STATES } from '../../content/states';



const ShippingInfo = React.memo(({ cartModeShippingAddressButtonRef }) => {

    const dispatch = useDispatch();

    const { cartMode } = useCartMode();

    const setPaymentIntent = useContext(MemberPopContext)?.setPaymentIntent;
    const setStep = useContext(MemberPopContext)?.setStep;

    const formRef = useRef(null);

    const uid = useSelector((state) => state.user.uid)
    const addressInfo = useSelector((state) => state.user.addressInfo)

    const [errors, setErrors] = useState(false);

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    // ONLY IN CART MODE MAY NOT WORK
    const cartCreatePaymentIntent = useCallback(() => {
        fetch(`${process.env.REACT_APP_URL_CREATE_PAYMENT_INTENT_CART}`)
            .then(response => response.json())
            .then((resp) => {

                if (resp.paymentIntent) {

                    setPaymentIntent({
                        intent: resp.paymentIntent,
                        id: resp.paymentIntentId
                    })

                    dispatch(actionMemberPop('cartBilling'));

                    setStep('cart')

                }

            })
    }, [setPaymentIntent, setStep]);

    const submit = useCallback((e) => {

        e.preventDefault();

        if (saving || saved) {
            return
        }

        let errorExists = false;

        let errorsVar = {

        };

        [...e.target.elements].forEach(field => {

            // get all elements minus the button
            if (field.tagName.toLowerCase() !== 'button') {

                const name = field.name;
                const value = field.value;

                // loop through all required
                if ((name !== 'address2') && !value || (field.name === 'state') && (value === 'state')) {
                    errorsVar[name] = true;
                    errorExists = true;
                }

            }

        })

        setErrors(errorsVar)

        if (!errorExists) {

            if (cartMode) {
                cartCreatePaymentIntent()
                return
            }

            setSaving(true)

            const formData = new FormData(e.target);

            let obj = Object.fromEntries(formData);

            obj.uid = uid;

            getAccessToken().then(token => {

                obj.accessToken = token;

                return fetch(process.env.REACT_APP_URL_UPDATE_ADDRESS, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(obj),
                })

            })
                .then(response => response.json())
                .then(resp => {


                    if (resp?.error) {

                        setErrors({
                            base: true
                        })

                        setSaving(false)

                    } else {

                        setSaved(true)

                        const cityValue = document.querySelector('[name=city]')?.value;
                        const stateValue = document.querySelector('[name=state]')?.value;
                        const zipCodeValue = document.querySelector('[name=zipCode]')?.value;

                        const city = `${cityValue}, ${stateValue}, ${zipCodeValue}`;

                        localStorageSetCity(city)
                        dispatch(actionShoppingFromCity({ city }));



                    }

                })

        }

    }, [uid, setErrors, setSaving, saving, setSaved, saved, cartMode]);

    const onFocus = useCallback((e) => {
        e.target.closest('.inputBox').classList.add('active');
    }, []);

    const onBlur = useCallback((e) => {
        e.target.closest('.inputBox').classList.remove('active');
    }, []);

    useEffect(() => {

        if (addressInfo) {
            const formData = new FormData(formRef.current);

            for (var key of formData.keys()) {

                if (addressInfo[key]) {
                    const input = formRef.current.querySelector(`[name=${key}]`);
                    input.value = addressInfo[key];
                }

            }

            // state with default value not working, force update
            const stateInput = formRef.current.querySelector('[name=state]');
            stateInput.value = addressInfo.state;

        }

    }, [addressInfo]);

    useEffect(() => {

        if (saved) {

            setTimeout(() => {

                setSaving(false)
                setSaved(false)

            }, 2500)

        }

    }, [saved]);

    return (
        <ShippingBox className="column" method="POST" onSubmit={submit} ref={formRef}>

            <div className="halfGrouping row">

                <div className="field column">

                    <div className={`inputBox row`}>
                        <label htmlFor="firstName">{DEFAULT_TEXT['page.myaccount.shippingInfo.firstName.placeholder']}</label>
                        <input type="text" name="firstName" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.firstName.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                    </div>

                    {errors?.firstName && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.firstName']}</div>}

                </div>

                <div className="field column">

                    <div className={`inputBox row`}>
                        <label htmlFor="lastName">{DEFAULT_TEXT['page.myaccount.shippingInfo.lastName.placeholder']}</label>
                        <input type="text" name="lastName" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.lastName.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                    </div>

                    {errors?.lastName && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.lastName']}</div>}

                </div>

            </div>

            <div className="field column">

                <div className={`inputBox row`}>
                    <label htmlFor="phoneNumber">{DEFAULT_TEXT['page.myaccount.shippingInfo.phoneNumber.placeholder']}</label>
                    <input type="tel" name="phoneNumber" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.phoneNumber.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                </div>

                {errors?.phoneNumber && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.phoneNumber']}</div>}

            </div>

            <div className="field column">

                <div className={`inputBox row`}>
                    <label htmlFor="address">{DEFAULT_TEXT['page.myaccount.shippingInfo.address.placeholder']}</label>
                    <input type="text" name="address" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.address.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                </div>

                {errors?.address && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.address']}</div>}

            </div>

            <div className="field column">

                <div className={`inputBox row`}>
                    <label htmlFor="address2">{DEFAULT_TEXT['page.myaccount.shippingInfo.address2.placeholder']}</label>
                    <input type="text" name="address2" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.address2.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                </div>

            </div>


            <div className="field column">

                <div className={`inputBox row`}>
                    <label htmlFor="city">{DEFAULT_TEXT['page.myaccount.shippingInfo.city.placeholder']}</label>
                    <input type="text" name="city" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.city.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                </div>

                {errors?.city && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.city']}</div>}

            </div>

            <div className="halfGrouping row">

                <div className="field column">
                    <div className={`inputBox row`}>
                        <label htmlFor="state">{DEFAULT_TEXT['page.myaccount.shippingInfo.state.placeholder']}</label>
                        <select name="state" defaultValue='state'>
                            <option disabled value="state">{DEFAULT_TEXT['page.myaccount.shippingInfo.state.placeholder']}</option>
                            {STATES.map(x => <option key={x.value} value={x.value}>{x.label}</option>)}
                        </select>
                    </div>

                    {errors?.state && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.state']}</div>}

                </div>

                <div className="field column">
                    <div className={`inputBox row`}>
                        <label htmlFor="zipCode">{DEFAULT_TEXT['page.myaccount.shippingInfo.zipCode.placeholder']}</label>
                        <input type="text" name="zipCode" placeholder={DEFAULT_TEXT['page.myaccount.shippingInfo.zipCode.placeholder']} onFocus={onFocus} onBlur={onBlur} />
                    </div>

                    {errors?.zipCode && <div className="error">{DEFAULT_TEXT['page.myaccount.shippingInfo.errors.zipCode']}</div>}

                </div>

            </div>

            {errors.base && <div className="error base" dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['page.myaccount.shippingInfo.errors.base'] }}></div>}

            <button ref={cartModeShippingAddressButtonRef} className={`${cartMode ? 'cartMode' : ''}`}>

                {saved ?
                    <div className="savedBox row">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z" />
                        </svg>
                        <span>{DEFAULT_TEXT['button.myaccount.tab.shippingInfo.success']}</span>
                    </div>
                    :
                    !saving ?
                        DEFAULT_TEXT['button.myaccount.tab.shippingInfo.save']
                        :
                        <LdsRing />
                }
            </button>

        </ShippingBox>
    );
})

export default ShippingInfo;