import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { getAccessToken } from '../helpers';
import LdsRing from '../snippet/LdsRing';
import AddPaymentMethodProvider from './AddPaymentMethod';
import Card from './Card';

const initialStripeState = {
    loading: false,
    client_secret: false,
    stripeReady: false
}

const PaymentMethods = React.memo(() => {

    const [setupIntentSecret, setSetupIntentSecret] = useState(initialStripeState);

    const addPaymentLoaded = (setupIntentSecret?.client_secret && setupIntentSecret.stripeReady) ? true : false;

    const uid = useSelector((state) => state.user.uid)
    const paymentMethods = useSelector((state) => state.user.paymentMethods)


    const addCard = useCallback(() => {

        setSetupIntentSecret(x => ({
            ...x,
            loading: true
        }))

        getAccessToken().then(token => {

            return fetch(process.env.REACT_APP_URL_ADD_CARD, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken: token }),
            })
                .then(response => response.json())
                .then(({ client_secret }) => {

                    if (client_secret) {

                        setSetupIntentSecret(x => ({
                            ...x,
                            client_secret
                        }))

                    }

                })

        })

    }, [uid, setSetupIntentSecret]);


    const closeAddPayment = useCallback(() => {

        setSetupIntentSecret(initialStripeState)

    }, []);

    return (
        <>
            <div className="paymentMethods column">

                <div className="topBar column">

                    <div className={`smallBtn row ${setupIntentSecret.loading ? 'loading' : addPaymentLoaded ? 'closeBtn' : ''}`} onClick={addPaymentLoaded ? closeAddPayment : addCard}>

                        <LdsRing />

                        {addPaymentLoaded && <svg xmlns="http://www.w3.org/2000/svg" className='closeIcon' viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>}

                        <span>
                            {addPaymentLoaded
                                ?
                                DEFAULT_TEXT['button.myaccount.tab.paymentMethods.close']
                                :
                                DEFAULT_TEXT['button.myaccount.tab.paymentMethods.addNew']
                            }
                        </span>

                    </div>

                    {setupIntentSecret?.client_secret && <AddPaymentMethodProvider setupIntentSecret={setupIntentSecret} setSetupIntentSecret={setSetupIntentSecret} />}

                </div>

                {(paymentMethods && paymentMethods?.length) ?

                    paymentMethods.map(x => <Card {...x} key={x.id} />)

                    :

                    <div className='noHistory'>{DEFAULT_TEXT['page.myaccount.tab.paymentMethods.noSaved']}</div>

                }
            </div>
        </>
    );
})

export default PaymentMethods;