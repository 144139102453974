import React from 'react';
import { CURRENCY } from '../../content/currency';
import { createPrice } from '../helpers';

const HighBidder = React.memo(({ displayName, avatar, bidderPrice }) => {

    return (
        <div className="bidder row">

            <div className="bidderLeft row">
                <img src={`/img/avatars/${avatar}.png`} />
                <span>{displayName}</span>
            </div>

            <div className="priceRight">
                {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{createPrice(bidderPrice)}
            </div>

        </div>
    );
})

export default HighBidder;