import React, { useEffect, useLayoutEffect, useCallback, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { ResetSuccess, SigninBox, SuccessCheckBlurb } from '../../style/main';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../snippet/Logo';
import { DEFAULT_TEXT, DISPLAY_NAME_MAX_CHARACTERS } from '../../content';
import LdsRing from '../snippet/LdsRing';
import { scrollTop } from '../helpers';
import { actionCloseMenus } from '../../reducers/layout';
import { submitSignup } from './functions/submitSignup';
import { signupSvgs } from './functions/svgs';

const completeSignin = async ({ email, password, navigate }) => {

    const auth = getAuth();

    await signInWithEmailAndPassword(auth, email, password)

    navigate('/', { replace: true });

}

const Signup = React.memo(({ page }) => {

    const { register, handleSubmit, watch, setValue, reset, getValues, setError, clearErrors, formState: { errors } } = useForm();

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const userNotFound = useSelector((state) => state.user.userNotFound)

    const [loading, setLoading] = useState(false)

    const [resetPassDone, setResetPassDone] = useState(false)

    const [forgotPassOk, setForgotOk] = useState(false)

    const resetForm = useCallback(() => {
        reset({
            email: '',
            password: '',
            displayName: ''
        })
    }, []);

    useEffect(() => {

        const subscription = watch((value, { name, type }) => {

            clearErrors('base')

            let test = name === 'displayName' || name === 'email';

            let inputValue = value[name];

            if (test && /\s+/g.test(inputValue)) {

                inputValue = inputValue.replace(/\s+/g, '')
                setValue(name, inputValue);

            }


        });

        return () => subscription.unsubscribe();

    }, [watch]);


    const onSubmit = useCallback(async () => {

        const setBaseError = message => {
            setLoading(false)
            setError('base', { type: 'custom', message });
        }

        setLoading(true)

        if (loading) {
            return
        }

        const email = getValues("email");
        const password = getValues("password");
        const displayName = getValues("displayName");

        const obj = {
            email,
            password,
            displayName,
            avatar: 0
        }

        switch (page) {
            case 'signup':

                const result = await submitSignup(obj, page);

                if (result?.success) {

                    completeSignin({ email, password, navigate })

                } else {

                    setBaseError(result)

                }

                break;
            case 'signin':

                try {

                    completeSignin({ email, password, navigate })

                } catch (e) {

                    const message = DEFAULT_TEXT[`form.baseError.whoops.${page}`]
                    setBaseError(message)

                }

                break;
            case 'forgotPassword':

                try {

                    await sendPasswordResetEmail(getAuth(), email)

                    setForgotOk(true)
                    setLoading(false)
                    resetForm()

                } catch (e) {

                    const message = DEFAULT_TEXT['form.baseError.forgotPassword']

                    setForgotOk(false)
                    setBaseError(message)

                }

                break;
            case 'resetPassword':

                try {
                    const actionCode = searchParams.get('oobCode')

                    const email = await verifyPasswordResetCode(getAuth(), actionCode)

                    const confirm = await confirmPasswordReset(getAuth(), actionCode, password)

                    setResetPassDone(true)

                    setTimeout(async () => {

                        const complete = await completeSignin({ email, password, navigate })

                    }, 3000)

                } catch (e) {

                    const message = DEFAULT_TEXT['form.baseError.resetPassword']

                    setBaseError(message)

                }

                break;

        }

    }, [loading, page]);

    const onFocus = useCallback((e) => {

        e.target.parentElement.classList.add('active');

    }, []);

    const onBlur = useCallback((e) => {
        e.target.parentElement.classList.remove('active');
    }, []);

    useEffect(() => {

        // when signup user is not found, possibly due to deleted account
        if (userNotFound) {

            const message = DEFAULT_TEXT['form.baseError.userNotFound']

            setBaseError(message)

        }

    }, [userNotFound]);

    useEffect(() => {

        // CLOSE ALL OPEN MENUS
        dispatch(actionCloseMenus({
            menu: true,
            dealDropdown: true,
            myAccountMenu: true,
            shoppingFrom: true
        }))

        resetForm()
        setForgotOk(false)
        setResetPassDone(false)
        scrollTop();

    }, [location]);

    return (
        <SigninBox className="column">

            <form onSubmit={handleSubmit(onSubmit)} className={`column ${page}`}>

                <div className="logoBox row">
                    <Logo />
                </div>

                <div className="innerForm row">

                    {page === 'signup' &&
                        <div className={`formLeft column ${page}`}>
                            <h1 dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['page.signup.title'] }} />
                            <ul className='column'>

                                {signupSvgs.map(({ svg }, i) =>
                                    <li key={i}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" dangerouslySetInnerHTML={{ __html: svg }} />
                                        <span>{DEFAULT_TEXT[`page.signup.icon.${i}`]}</span>
                                    </li>
                                )}

                            </ul>
                            <div className='button createBottom row' onClick={scrollTop}>{DEFAULT_TEXT['button.signup.bottom']}</div>
                        </div>
                    }

                    <div className={`formRight column ${page}`}>

                        <div className="blurb column">
                            <h2>{DEFAULT_TEXT[`page.${page}.title`]}</h2>
                            <span className="blurbSmall">{DEFAULT_TEXT[`page.${page}.subtitle`]}</span>
                        </div>

                        <div className="fields column">

                            {page !== 'resetPassword' &&

                                <div className="field column">
                                    <div className='inputBox row'>
                                        <label htmlFor="email">Email</label>

                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={DEFAULT_TEXT['form.email.placeholder']}
                                            {...register("email", {
                                                required: DEFAULT_TEXT['form.email.error.required'],
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: DEFAULT_TEXT['form.email.error.valid']
                                                }
                                            })}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />

                                    </div>

                                    {errors?.email?.message && <div className="error">{errors?.email?.message}</div>}

                                </div>

                            }

                            {page === 'signup' &&

                                <div className="field column">
                                    <div className='inputBox row'>
                                        <label htmlFor="displayName">Display Name</label>

                                        <input
                                            type="displayName"
                                            name="text"
                                            placeholder={DEFAULT_TEXT['form.displayName.placeholder']}
                                            {...register("displayName", {
                                                required: DEFAULT_TEXT[`form.displayName.error.required`],
                                                minLength: {
                                                    value: 3,
                                                    message: DEFAULT_TEXT['form.displayName.error.minimum'],
                                                }
                                            })}
                                            maxLength={DISPLAY_NAME_MAX_CHARACTERS}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />

                                    </div>

                                    {errors?.displayName?.message && <div className="error">{errors?.displayName?.message}</div>}

                                </div>

                            }

                            {page !== 'forgotPassword' &&

                                <div className="field column">
                                    <div className='inputBox row'>
                                        <label htmlFor="password">Password</label>

                                        <input
                                            type="password"
                                            name="password"
                                            placeholder={DEFAULT_TEXT['form.password.placeholder']}
                                            {...register("password", {
                                                required: DEFAULT_TEXT[`form.password.${page}.error.required`],
                                                minLength: {
                                                    value: 6,
                                                    message: DEFAULT_TEXT['form.password.error.minimum'],
                                                }
                                            })}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />

                                    </div>

                                    {errors?.password?.message && <div className="error">{errors?.password?.message}</div>}

                                </div>
                            }

                            {page === 'signin' &&
                                <Link to={DEFAULT_TEXT['url.forgotPassword']} className="forgotPassword row">
                                    <span>{DEFAULT_TEXT['url.forgotPassword.text']}</span>
                                </Link>
                            }

                        </div>

                        {errors?.base?.message && <div className="error base" dangerouslySetInnerHTML={{ __html: errors?.base?.message }}></div>}

                        {forgotPassOk &&
                            <SuccessCheckBlurb className='row'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path></svg>

                                <div className="text">
                                    {DEFAULT_TEXT['form.success.forgotPassword']}
                                </div>
                            </SuccessCheckBlurb>
                        }

                        <button type="submit">

                            {!loading ?

                                DEFAULT_TEXT[`button.${page}`]

                                :

                                <LdsRing />

                            }

                        </button>

                        <div className="alreadyMember row">
                            <span>{DEFAULT_TEXT[`page.${page}.under.button.text`]} </span>
                            <Link to={page === 'signup' ? DEFAULT_TEXT['url.signin'] : DEFAULT_TEXT['url.signup']}>
                                {page === 'signup' ? DEFAULT_TEXT['url.signin.text'] : DEFAULT_TEXT['button.signup']}
                            </Link>
                        </div>

                    </div>

                </div>

            </form>

            <div className="background"></div>

            {resetPassDone &&
                <ResetSuccess className="column">
                    <LdsRing />
                    <div className="text">{DEFAULT_TEXT['form.success.resetPassword']}</div>
                </ResetSuccess>
            }

        </SigninBox>
    );
})

export default Signup;