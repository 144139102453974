import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TEXT } from '../../content';
import { useCartMode } from '../../providers/CartMode';
import { actionMemberPop, actiondDeductCredit, actionDeductCredits } from '../../reducers/user';
import { BidButtonBox } from '../../style/main';
import { getAccessToken } from '../helpers';
import { socket } from '../Sockets';


const BidButton = React.memo(({ loading, id, highBidder, sold, priceRef, countdownZero }) => {

    const { cartMode } = useCartMode();

    const dispatch = useDispatch();

    const timeout = useRef(null)
    const timeoutLockBid = useRef(null)
    const bidButtonRef = useRef(null)

    const navigate = useNavigate();

    const [alreadyHighBidder, setAlreadyHighBidder] = useState();
    const [lockBid, setLockBid] = useState(false);

    const uid = useSelector((state) => state.user.uid)
    const credits = useSelector((state) => state.user.credits)

    useEffect(() => {

        if (uid && (uid !== highBidder)) {

            clearTimeout(timeout.current)
            setAlreadyHighBidder(false)

        }

    }, [id, highBidder]);

    const bid = useCallback(async () => {

        if (cartMode) {

            dispatch(actionMemberPop('cartModeShipping'));

        } else if (!uid) {

            navigate(DEFAULT_TEXT['url.signup']);

        } else if (!credits || credits <= 0) {

            // no credits
            dispatch(actionMemberPop('addCredits'));

        } else if (sold || countdownZero) {

            return

        } else {

            // already the high bidder
            if (uid === highBidder) {

                clearTimeout(timeout.current)

                setAlreadyHighBidder(true)

                timeout.current = setTimeout(() => {

                    setAlreadyHighBidder(false)

                }, 1000)

            } else if (lockBid) {

                return

            } else {

                setLockBid(true)

                const priceRefCurrent = priceRef?.current;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;

                if (windowWidth < 768 && priceRefCurrent) {

                    const priceDistanceFromTop = priceRefCurrent.getBoundingClientRect().top;
                    const priceTop = priceRefCurrent.offsetTop;

                    // 1ST CHECK IS TO SEE IF PRICE IS OUT OF TOP OF WINDOW. 2ND CHECK TO SEE IF PRICE IS OUT OF BOTTOM OF THE WINDOW
                    if (priceDistanceFromTop < 0 || windowHeight - priceDistanceFromTop - priceRefCurrent.offsetHeight - bidButtonRef.current.offsetHeight < 0) {

                        window.scrollTo(0, priceTop)

                    }

                }

                dispatch(actionDeductCredits())

                const accessToken = await getAccessToken()

                socket.emit('bid', { id, uid, accessToken });


            }


        }

    }, [uid, id, highBidder, sold, countdownZero, priceRef, cartMode, lockBid, credits]);

    useEffect(() => {

        if (lockBid) {

            timeoutLockBid.current = setTimeout(() => {
                setLockBid(false)
            }, 1000)

        }

        return () => {
            clearTimeout(timeoutLockBid.current)
        }

    }, [lockBid]);

    return (
        <BidButtonBox className='column' ref={bidButtonRef}>

            <button onClick={loading ? null : bid}>{!cartMode ? DEFAULT_TEXT['button.bid'] : DEFAULT_TEXT['button.bid.carMode']}</button>

            <div className={`alreadyHightBidder column ${alreadyHighBidder ? 'active' : ''}`}>
                <div className="text">{DEFAULT_TEXT['auction.highBidder']}</div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17.948">
                    <polygon id="arrow-37" points="12,17.948 0,-0.052 24,-0.052 " />
                </svg>
            </div>

        </BidButtonBox>
    );
})

export default BidButton;