import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionMemberPop } from '../../reducers/user';
import { AvatarImage } from '../../style/main';

const Avatar = ({ type }) => {

    const dispatch = useDispatch();

    const openAvatars = useCallback(() => {

        dispatch(actionMemberPop('avatars'));

    }, []);

    const avatar = useSelector((state) => state.user.avatar)

    return (
        <AvatarImage className={`row ${type}`} onClick={openAvatars}>

            {avatar > 0 && <img src={`${process.env.REACT_APP_URL_IMAGE_GATEWAY}avatars/${avatar}.png`} />}

        </AvatarImage>
    );
}

export default Avatar;