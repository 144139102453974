import { createSlice, current } from '@reduxjs/toolkit'


export const auctionSlice = createSlice({
    name: 'layout',
    initialState: {
        menuOpen: false,
        dealDropdownOpen: false,
        bodyOverflowHidden: false,
        myAccountMenu: false,
        shoppingFrom: {
            city: '',
            open: false,
            cities: [],
            updated: false,
            error: false
        }
    },
    reducers: {
        actionToggleMenu: (state, action) => {

            const menuOpen = current(state)?.menuOpen;
            state.menuOpen = !menuOpen;

        },
        actionToggleDealdropdown: (state, action) => {

            const dealDropdownOpen = current(state)?.dealDropdownOpen;
            state.dealDropdownOpen = !dealDropdownOpen;

        },
        actionToggleMyAccountMenu: (state, action) => {

            const myAccountMenu = current(state)?.myAccountMenu;
            state.myAccountMenu = !myAccountMenu;

        },
        actionToggleShoppingFromMenu: (state, action) => {

            state.shoppingFrom.open = !current(state)?.shoppingFrom.open;

        },
        actionCloseMenus: (state, action) => {

            const { menu, dealDropdown, myAccountMenu, shoppingFrom } = action.payload;

            if (menu) {
                state.menuOpen = false;
            }

            if (dealDropdown) {
                state.dealDropdownOpen = false;
            }

            if (myAccountMenu) {
                state.myAccountMenu = false;
            }

            if (shoppingFrom) {
                state.shoppingFrom.open = false;
            }

        },
        actionShoppingFromCities: (state, action) => {

            state.shoppingFrom.cities = action.payload;

        },
        actionShoppingFromCity: (state, action) => {

            const { city, updated } = action.payload;

            state.shoppingFrom.city = city;
            state.shoppingFrom.cities = [];
            state.shoppingFrom.open = false;

            if (updated) {
                state.shoppingFrom.updated = true;
            }

        },
        actionShoppingFromUpdatedFalse: (state, action) => {

            state.shoppingFrom.updated = false;

        },
        actionShoppingFromError: (state, action) => {

            state.shoppingFrom.error = action.payload;

        },
    }
})

export const { actionToggleMenu, actionToggleDealdropdown, actionCloseMenus, actionToggleMyAccountMenu, actionToggleShoppingFromMenu, actionShoppingFromCities, actionShoppingFromCity, actionShoppingFromUpdatedFalse, actionShoppingFromError } = auctionSlice.actions

export default auctionSlice.reducer