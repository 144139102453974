import React from 'react';
import ReactPlayer from 'react-player'
import { IntroVideoBox } from '../../style/main';

const IntroVideo = React.memo(() => {
    return (
        <IntroVideoBox className='row'>
            <div className="wrap row">
                <div className="leftSide column">
                    <h1>Brand name<br /> Savings</h1>
                    <div className="h1Sub">Get huge savings on brand name items you know and love.</div>
                </div>
                <ReactPlayer
                    url="/video/intro.mp4"
                    controls
                    className='videoBox row'
                    width="inherit"
                    height="initial"
                />
            </div>
        </IntroVideoBox>
    );
})

export default IntroVideo;