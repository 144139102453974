import React, { useEffect, useState, useCallback, createContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StripeProvider from './memberPop/Stripe';
import Credits from './memberPop/Credits';
import { DEFAULT_TEXT } from '../content';
import { MemberPopBox } from '../style/main';
import { actionMemberPop, actionUpdateAvatar } from '../reducers/user';
import Avatars from './memberPop/Avatars';
import PurchasedBids from './memberPop/PurchasedBids';
import LdsRing from './snippet/LdsRing';
import EditDisplayName from './memberPop/EditDisplayName';
import CardListing from './memberPop/CardListing';
import { getAccessToken } from './helpers';
import { useNavigate } from 'react-router';
import ShippingInfo from './myaccount/ShippingInfo';

export const MemberPopContext = createContext(null)

const MemberPop = React.memo(() => {

    const dispatch = useDispatch();

    const cartModeShippingAddressButtonRef = useRef(null);

    const navigate = useNavigate();

    const submitButtonRef = useRef(null)
    const submitEditDisplayRef = useRef(null)

    const uid = useSelector((state) => state.user.uid)
    const memberPop = useSelector((state) => state.user.memberPop)
    const avatar = useSelector((state) => state.user.avatar)
    const paymentMethods = useSelector((state) => state.user.paymentMethods)

    const [paymentIntent, setPaymentIntent] = useState({
        intent: false,
        id: false
    });

    const [bidPackage, setBidPackage] = useState(1);

    const [cardSelected, setCardSelected] = useState(0);

    const [refillError, setRefillError] = useState(false);

    const [loading, setLoading] = useState(false);

    const [stripeLoaded, setStripeLoaded] = useState(false);
    const [waitingForStripe, setWaitingForStripe] = useState(false);


    const [avatarCount, setAvatarCount] = useState(avatar);

    const [step, setStep] = useState(0);

    let buttonText;
    let title;

    // TITLES AND BUTTON TEXT
    if (memberPop === 'addCredits') {

        if (step === 0) {

            title = DEFAULT_TEXT['member.pop.title.addCredits']
            buttonText = DEFAULT_TEXT['member.pop.button.addCredits'];

        } else if (step === 'creditCardListing' && cardSelected === paymentMethods.length) {

            // WHEN ADD NEW CREDIT CARD IS SELECTED
            buttonText = DEFAULT_TEXT['member.pop.button.addCards'];

        } else if (step === 'creditCardListing' && cardSelected !== paymentMethods.length) {

            // WHEN NORMAL CREDIT CARD IS SELECTED
            buttonText = DEFAULT_TEXT['member.pop.button.creditCard'];

        } else {

            title = DEFAULT_TEXT['member.pop.title.creditCard']
            buttonText = DEFAULT_TEXT['member.pop.button.creditCard'];

        }

        // CREDIT CARD LISTING ONLY CHANGE TITLE
        if (step === 'creditCardListing') {
            title = DEFAULT_TEXT['member.pop.title.creditCardListing']
        }

    } else {

        // NORMAL STEPS OUTS OF ADD CREDITS, AVATAR ETC.
        title = DEFAULT_TEXT[`member.pop.title.${memberPop}`]
        buttonText = DEFAULT_TEXT[`member.pop.button.${memberPop}`];

    }

    useEffect(() => {

        if (waitingForStripe && stripeLoaded) {

            setStep('addCreditCard')
            setWaitingForStripe(false)

        }


    }, [waitingForStripe, stripeLoaded, setWaitingForStripe]);

    const reAddBids = useCallback(() => {

        setRefillError(false)

        // GET THE PAYMENT METHOD ID BASED ON THE CARD SELECTED INDEX
        const id = paymentMethods[cardSelected].id;

        getAccessToken().then(token => {

            return fetch(process.env.REACT_APP_URL_CARD_LISTING_PAYMENT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken: token, id, bidPackage }),
            })
                .then(response => response.json())
                .then(resp => {

                    if (resp?.succeeded) {

                        // NEEDED PARAMS TO LAUNCH THE POPUP
                        const params = `?redirect_status=succeeded&bidPackage=${bidPackage}`

                        navigate(`${window.location.pathname}${params}`, { replace: true });
                        window.location.reload();

                    } else {

                        throw 'Error adding bids';

                    }

                }).catch(e => {

                    setRefillError(true)

                })


        })

    }, [cardSelected, paymentMethods, bidPackage]);

    const nextStep = useCallback(() => {

        if (loading || waitingForStripe) {
            return
        }

        // ADD CREDITS STEP
        if (memberPop === 'addCredits') {

            if (step === 0 && paymentMethods?.length) {

                // GO TO CREDIT CARD LISTING IF CARDS EXISTS
                setStep('creditCardListing')

            } else if (step === 0 && !paymentMethods?.length || step === 'creditCardListing' && cardSelected === paymentMethods?.length) {

                // IF ON STEP 0, AND NO PAYMENT METHODS EXIST, OR WHEN CLICKING THE "ADD THE CREDIT CARD" BOX

                // GO TO CHECKOUT FORM
                if (stripeLoaded) {

                    setStep('addCreditCard')

                } else {

                    setWaitingForStripe(true)

                }

            } else if (step === 'creditCardListing' && cardSelected !== paymentMethods?.length) {

                setLoading(true)
                reAddBids()

            } else {

                // USE THE FORM BUTTON TO SUBMIT
                submitButtonRef.current.click()

            }

        } else if (memberPop === 'avatars' && avatarCount) {


            setLoading(true)

            getAccessToken().then(token => {

                return fetch(`${process.env.REACT_APP_URL_UPDATE_AVATAR}?avatar=${avatarCount}&accessToken=${token}`)
                    .then(response => response.json())
                    .then(resp => {

                        if (resp?.updatedAvatar) {

                            dispatch(actionUpdateAvatar(avatarCount));

                            // AVATAR EXISTS, CLOSE THE BOX, ONLY AN UPDATE
                            if (avatar) {

                                dispatch(actionMemberPop(false));

                            } else {

                                // AVATAR DOES NOT EXIST AFTER SIGNUP, GO TO ADD CREDITS
                                dispatch(actionMemberPop('addCredits'));
                                setLoading(false)

                            }

                        } else {

                            throw 'error updating avatar'

                        }

                    }).catch(e => {

                        console.error(e)

                    })


            })

        } else if (memberPop === 'bidsAdded') {

            dispatch(actionMemberPop(false));

        } else if (memberPop === 'editDisplayName') {

            // USE THE FORM BUTTON TO SUBMIT EDIT DISPLAY NAME
            submitEditDisplayRef.current.click()

        } else if (memberPop === 'cartModeShipping') {

            cartModeShippingAddressButtonRef.current.click()

        } else if (memberPop === 'idleBox') {

            // refresh page
            navigate(0);

        }



    }, [uid, memberPop, step, avatar, avatarCount, setLoading, stripeLoaded, cardSelected, paymentMethods, loading, waitingForStripe]);


    const goBack = useCallback(() => {

        if (loading || waitingForStripe) {
            return
        }

        if (memberPop === 'addCredits') {

            if (step === 'creditCardListing' || (step === 'addCreditCard') && (!paymentMethods?.length)) {

                setStep(0)

            } else if ((step === 'addCreditCard') && (paymentMethods?.length)) {

                setStep('creditCardListing')

            } else {

                dispatch(actionMemberPop(false));

            }



        } else if (memberPop === 'idleBox') {

            // refresh page
            navigate(0);

        } else {

            dispatch(actionMemberPop(false));

        }

    }, [setStep, step, loading, waitingForStripe]);


    return (

        <MemberPopContext.Provider value={{
            bidPackage,
            setBidPackage,
            paymentIntentId: paymentIntent.id,
            paymentIntent: paymentIntent.intent,
            setPaymentIntent,
            step,
            setStep,
            submitButtonRef,
            submitEditDisplayRef,
            avatarCount,
            setAvatarCount,
            loading,
            setLoading,
            setStripeLoaded,
            cardSelected,
            setCardSelected,
            refillError
        }}>

            <MemberPopBox className={memberPop === 'idleBox' ? 'blur' : ''}>

                <div className={`inner column ${memberPop === 'avatars' ? 'avatarsFormInner' : ''}`}>

                    <h4 className='row'>

                        {memberPop === 'idleBox' || avatar || memberPop === 'cartModeShipping' ?
                            <div className="svgBox column" onClick={goBack}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M 13 5.9296875 L 6.9296875 12 L 13 18.070312 L 14.5 16.570312 L 9.9296875 12 L 14.5 7.4296875 L 13 5.9296875 z" />
                                </svg>
                            </div>
                            :
                            ''
                        }

                        <span>{title}</span>

                    </h4>


                    <div className={`formOuter column ${memberPop === 'idleBox' ? 'hide' : memberPop === 'avatars' ? 'avatarsForm' : memberPop === 'bidsAdded' ? 'bidsAddedForm' : ''}`}>

                        {memberPop === 'addCredits' ?

                            <>
                                <Credits />

                                {step === 'creditCardListing' && <CardListing />}

                                {paymentIntent.intent && <StripeProvider />}

                            </>

                            :
                            memberPop === 'avatars' ?
                                <Avatars />
                                :
                                memberPop === 'bidsAdded' ?
                                    <PurchasedBids />
                                    :
                                    memberPop === 'editDisplayName' ?
                                        <EditDisplayName />
                                        :
                                        memberPop === 'cartModeShipping' ?
                                            <ShippingInfo cartModeShippingAddressButtonRef={cartModeShippingAddressButtonRef} />
                                            :
                                            memberPop === 'cartBilling' && paymentIntent.intent ?
                                                <StripeProvider />
                                                :
                                                ''
                        }
                    </div>

                    <div className={`buttonBox row ${memberPop === 'idleBox' ? 'idleBox' : ''}`}>
                        <button onClick={nextStep}>
                            {(loading || waitingForStripe) ?

                                <LdsRing />

                                :

                                buttonText

                            }
                        </button>
                    </div>

                </div>

            </MemberPopBox>

        </MemberPopContext.Provider>
    );
})

export default MemberPop;