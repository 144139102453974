const categoryText = Object.freeze({
    all: 'All Deals',
    apple: 'Apple',
    bidpack: 'Bid Packs',
    giftCard: 'Gift Cards',
    xbox: 'Xbox',
    playstation: 'Playstation',
    nintendo: 'Nintendo',
    samsung: 'Samsung',
    xbox: 'Xbox'
})

export const products = [
    {
        CATEGORY_SUB: 'bidpack25',
        PRODUCT_TITLE: '25 Bids Pack',
        PRODUCT_IMG: '25bids.png',
        PRODUCT_DESCRIPTION: `
            <p>
            <strong>More bids means more prizes!</strong>
            </p>

            <p>
            25 Bids will be credited to your account automatically.
            </p>
            
            <p>
            <strong><u>What are Bids?</u></strong>
            <br>
            Bids are used to win auctions. Each time you place a bid the price of the auction will increase by $0.01 and one single bid will be deducted from your account. Add more bids at anytime and use your bid pack to win any auction with absolutely no restrictions.            
            </p>
        `
    },
    {
        CATEGORY_SUB: 'bidpack50',
        PRODUCT_TITLE: '50 Bids Pack',
        PRODUCT_IMG: '50bids.png',
        PRODUCT_DESCRIPTION: `
            <p>
            <strong>More bids means more prizes!</strong>
            </p>

            <p>
            50 Bids will be credited to your account automatically.
            </p>
            
            <p>
            <strong><u>What are Bids?</u></strong>
            <br>
            Bids are used to win auctions. Each time you place a bid the price of the auction will increase by $0.01 and one single bid will be deducted from your account. Add more bids at anytime and use your bid pack to win any auction with absolutely no restrictions.            
            </p>
        `
    },
    {
        CATEGORY_SUB: 'bidpack100',
        PRODUCT_TITLE: '100 Bids Pack',
        PRODUCT_IMG: '100bids.png',
        PRODUCT_DESCRIPTION: `
            <p>
            <strong>More bids means more prizes!</strong>
            </p>

            <p>
            100 Bids will be credited to your account automatically.
            </p>
            
            <p>
            <strong><u>What are Bids?</u></strong>
            <br>
            Bids are used to win auctions. Each time you place a bid the price of the auction will increase by $0.01 and one single bid will be deducted from your account. Add more bids at anytime and use your bid pack to win any auction with absolutely no restrictions.            
            </p>
        `
    },
    {
        CATEGORY_SUB: 'bidpack250',
        PRODUCT_TITLE: '250 Bids Pack',
        PRODUCT_IMG: '250bids.png',
        PRODUCT_DESCRIPTION: `
            <p>
            <strong>More bids means more prizes!</strong>
            </p>

            <p>
            250 Bids will be credited to your account automatically.
            </p>
            
            <p>
            <strong><u>What are Bids?</u></strong>
            <br>
            Bids are used to win auctions. Each time you place a bid the price of the auction will increase by $0.01 and one single bid will be deducted from your account. Add more bids at anytime and use your bid pack to win any auction with absolutely no restrictions.            
            </p>
        `
    },
    {
        CATEGORY_SUB: 'giftCardAmazon',
        PRODUCT_TITLE: '$50 Amazon Gift Card',
        PRODUCT_IMG: 'amzgift.png',
        RETAIL_PRICE: '50.00',
        PRODUCT_DESCRIPTION: `
        <p>
            <strong>Receive your gift $50 Amazon gift card immediately. Gift cards are sent by email.</strong>
        </p>
        <p>
        Amazon.com Gift Cards never expire and can be redeemed towards millions of items at www.amazon.com.
        </p>
        <p>
        <strong><u>To redeem your gift card:</u></strong><br>
        1. Visit www.amazon.com/redeem.<br>
        2. Enter the Claim Code when prompted.<br>
        3. Gift card funds will be applied automatically to eligible orders during the checkout process.
        </p>
        <p>
        If you have questions about redeeming your gift card, please visit www.amazon.com/gc-redeem.
        </p>
        `
    },
    {
        CATEGORY_SUB: 'giftCardWalmart',
        PRODUCT_TITLE: '$50 WalMart Gift Card',
        PRODUCT_IMG: 'giftcard1.png',
        RETAIL_PRICE: '50.00',
        PRODUCT_DESCRIPTION: `
            <p>
                <strong>Receive your gift $50 WalMart gift card immediately. Gift cards are sent by email.</strong>
            </p>
            <p>
            <strong><u>About your Walmart Gift Card:</u></strong><br>
            &bull; Gift Cards may be redeemed at Walmart stores, Walmart.com, Sam's Club, and Samsclub.com by SAM's Club members.<br>
            &bull; No expiration date.<br>
            &bull; Not returnable or refundable for cash except in states where required by law.            
            </p>
        `
    },
    {
        CATEGORY_SUB: 'giftCardTarget',
        PRODUCT_TITLE: '$50 Target Gift Card',
        PRODUCT_IMG: 'trget.png',
        RETAIL_PRICE: '50.00',
        PRODUCT_DESCRIPTION: `
            <p>
            <strong>Receive your gift $50 Target gift card immediately. Gift cards are sent by email.</strong>
            </p>
            <p>
            What is the best gift anytime? The Target GiftCard. No fees. No expiration. No kidding. Receive your Target GiftCard by email. These GiftCards can be used in store or on Target.com and may be reloaded at any Target store. Target GiftCards can only be used at Target stores and on Target.com, and cannot be used to purchase other gift cards or prepaid cards.
            </p>
            <p>
                <strong>Highlights</strong><br>
                &bull; What is the best gift anytime? The Target GiftCard. No fees. No expiration. No kidding.<br>
                &bull; EGiftCards can be used in store or on Target.com. To use Target eGiftCards in store, open the eGiftCard email or text message on a web-enabled mobile device and the Team Member will scan the barcode and enter the access code at check out.<br>
                &bull; GiftCards may be reloaded at any Target Store.
            </p>
        `
    },
    {
        CATEGORY_SUB: 'playstation',
        PRODUCT_TITLE: 'Playstation 5',
        RETAIL_PRICE: '499.99',
        PRODUCT_IMG: 'ps.png',
        PRODUCT_DESCRIPTION: `
            <p>
                The PS5 console unleashes new gaming possibilities that you never anticipated. Experience lightning fast loading with an ultra-high speed SSD, deeper immersion with support for haptic feedback, adaptive triggers, and 3D Audio*, and an all-new generation of incredible PlayStation games. *3D audio via built-in TV speakers or analog/USB stereo headphones. Set up and latest system software update required.
            </p>
            <p>
                <strong>Features</strong><br>
                Integrated I/O<br>
                Stunning games<br>
                Ultra-high speed SSD<br>
                HDR technology<br>
                Game Boost<br>
                Up to 120 fps with 120Hz output<br>
                Tempest 3D AudioTech<br>
                Haptic feedback<br>
                Adaptive triggers
            </p>
            <p>
                <strong>What's included</strong><br>
                Sony PlayStation 5 Console<br>
                Wireless controller<br>
                HDMI cable<br>
                AC power cord<br>
                USB cable<br>
                Printed materials
            </p>
        `
    },
    {
        CATEGORY_SUB: 'xbox',
        PRODUCT_TITLE: 'Microsoft Xbox Series X',
        PRODUCT_IMG: 'xbx.png',
        RETAIL_PRICE: '499.99',
        PRODUCT_DESCRIPTION: `
            <p>
            Xbox Series X, the fastest, most powerful Xbox ever. Explore rich new worlds with 12 teraflops of raw graphic processing power, DirectX ray tracing, a custom SSD, and 4K gaming. Make the most of every gaming minute with Quick Resume, lightning-fast load times, and gameplay of up to 120 FPS—all powered by Xbox Velocity Architecture. Enjoy thousands of games from four generations of Xbox, with hundreds of optimized titles that look and play better than ever. And when you add Xbox Game Pass Ultimate (membership sold separately or included when you choose Xbox All Access), you get an instant library of 100+ high-quality games, including day one releases from Xbox Game Studios like Halo Infinite, Forza Horizon 5, and Microsoft Flight Simulator.
            </p>

            <p>
                <strong>What's included</strong><br>
                Xbox Series X console<br>
                Xbox Wireless Controller - Carbon Black<br>
                Power cord<br>
                Ultra High Speed HDMI cable
            </p>

            `
    },
    {
        CATEGORY_SUB: 'nintendoSwitch',
        PRODUCT_TITLE: 'Nintendo Switch',
        PRODUCT_IMG: 'nswitch.png',
        RETAIL_PRICE: '299.99',
        PRODUCT_DESCRIPTION: `
            <p>
            Introducing Nintendo Switch, the new home video game system from Nintendo. In addition to providing single and multiplayer thrills at home, the Nintendo Switch system can be taken on the go so players can enjoy a full home console experience anytime, anywhere. The mobility of a handheld is now added to the power of a home gaming system, with unprecedented new play styles brought to life by the two new Joy-Con controllers.
            </p>

            <p>
            At home the system rests in the Nintendo Switch dock, which lets you play with family and friends on a TV in the comfort of your living room.
            By simply lifting Nintendo Switch from the dock at any time, the system instantly transitions to handheld mode, and the same great gaming experience that was being enjoyed at home now travels with you. The portability of Nintendo Switch is enhanced by its 6.2-inch screen, which displays bright, high-definition gameplay at the park, on an airplane, in a car, or at a friend's apartment. 
            </p>     

            <p>
            Nintendo Switch comes with two Joy-Con that can each act as a standalone controller with an analog stick, a full complement of face and shoulder buttons, built in motion-sensing technology and new HD Rumble. They can be inserted into a Joy-Con grip accessory to be used like a traditional controller, held comfortably in each hand for independent left and right motion controls, or shared between two players for instant multiplayer gaming in front of the TV or out on the go.
            </p>

            <p>
                <strong>What's included</strong><br>
                Nintendo Switch 32GB Console<br>
                Nintendo Switch dock<br>
                Joy-Con (L) in blue and Joy-Con (R) in red<br>
                Two Joy-Con strap accessories<br>
                One Joy-Con Grip<br>
                HDMI cable<br>
                AC adapter
            </p>
        `
    },
    {
        CATEGORY_SUB: 'playstationGame1',
        PRODUCT_TITLE: 'NBA 2K22',
        PRODUCT_IMG: 'psg1.png',
        RETAIL_PRICE: '19.99',
        PRODUCT_DESCRIPTION: `
            <p>
                <strong>System:</strong> Playstation 5
            </p>
            <p>
                NBA 2K22 puts the entire basketball universe in your hands. Anyone, anywhere can hoop in NBA 2K22.
            </p>
            <p>
                PLAY NOW in real NBA and WNBA environments against authentic teams and players. Build your own dream team in MyTEAM with today's stars and yesterday's legends. Live out your own pro journey in MyCAREER and experience your personal rise to the NBA. Flex your management skills as a powerful Executive in MyGM and MyLEAGUE. STEP YOUR GAME UP.
            </p>
            <p>
                New tactical offense meets an overhauled defense for a more competitive and immersive NBA 2K22. Add skill-based dribbling, shooting, dunking, and alley-oops to your bag of moves, and counter them with ferocious new blocks and contests on the other end of the court. YOUR JOURNEY, YOUR CITY MyCAREER and an all-new City become one in a groundbreaking 2K22 experience. 
            </p>
            <p>
                The NBA 2K experience offers more than just the sport of basketball; it is hoops culture and everything around it. Discover music artists in an ever-expanding 2K Beats soundtrack. Flaunt the latest trends and hoops fashion with your MyPLAYER. In NBA 2K, Everything is Game. Interactive elements: Users interact, in-game purchases.
            </p>

            <p>
                <strong>What's included</strong><br>
                NBA 2K22 [Physical]
            </p>

        `
    },
    {
        CATEGORY_SUB: 'playstationGame2',
        PRODUCT_TITLE: 'Call of Duty: Vanguard',
        PRODUCT_IMG: 'psg2.png',
        RETAIL_PRICE: '69.99',
        PRODUCT_DESCRIPTION: `
        <p>
            <strong>System:</strong> Playstation 5
        </p>

        <p>
            Rise on every front: Dogfight over the Pacific, airdrop over France, defend Stalingrad with a sniper's precision and blast through advancing forces in North Africa.

            The Call of Duty franchise returns with Call of Duty: Vanguard, developed by Sledgehammer Games, where players will be immersed in visceral WWII combat on an unprecedented global scale.

            Through a deeply engaging single player Campaign, a select group of soldiers from different countries rise to meet the world's gravest threat. Players will also make their mark across Call of Duty's signature Multiplayer experience, plus an exciting new Zombies experience developed by Treyarch.

        </p>
        <p>
            Call of Duty: Vanguard will also usher in a new and unparalleled Call of Duty: Warzone integration post launch, and feature cross-progression and cross-generation play

            2021 Activision Publishing, Inc. ACTIVISION, CALL OF DUTY, CALL OF DUTY VANGUARD, CALL OF DUTY WARZONE, and WARZONE are trademarks of Activision Publishing, Inc.

            All other trademarks and trade names are the properties of their respective owners.
        </p>

        <p>
        <strong>What's included</strong><br>
        Call of Duty: Vanguard [Physical]
        </p>
        `
    },
    {
        CATEGORY_SUB: 'nintendoSwitchGame1',
        PRODUCT_TITLE: 'Metroid Dread',
        PRODUCT_IMG: 'nsgame1.png',
        RETAIL_PRICE: '59.99',
        PRODUCT_DESCRIPTION: `

            <p>
                <strong>System:</strong> Nintendo Switch
            </p>

            <p>
            Join intergalactic bounty hunter Samus Aran in her first new 2D Metroid™ story in 19 years Samus' story continues after the events of the Metroid Fusion game when she descends upon planet ZDR to investigate a mysterious transmission sent to the Galactic Federation. The remote planet has become overrun by vicious alien lifeforms and chilling mechanical menaces. Samus is more agile and capable than ever, but can she overcome the inhuman threat stalking the depths of ZDR?Face off against unrelenting E.M.M.I. robots Once DNA-extracting research machines, the imposing E.M.M.I. are now hunting Samus down. Tensions are high as you evade these E.M.M.I. to avoid a cruel death while finding a way to take them down. 
            </p>

            <p>
            Find out what turned these robotic wonders into the scourge of ZDR and escape with your life. Feel Samus' power grow as you gain maneuvers and abilities Acquire new and familiar abilities as you traverse the many environments of this dangerous world. Parkour over obstacles, slide through tight spaces, counter enemies, and battle your way through the planet. Return to areas and use your new abilities to find upgrades, alternate paths, and a way forward. Explore the sprawling map, evade and destroy E.M.M.I. robots, and overcome the dread plaguing ZDR.
            </p>

            <p>
            <strong>What's included</strong><br>
            Metroid Dread [Physical]
            </p>

            `
    },
    {
        CATEGORY_SUB: 'nintendoSwitchGame2',
        PRODUCT_TITLE: 'Mario Kart 8 Deluxe',
        PRODUCT_IMG: 'nsgame2.png',
        RETAIL_PRICE: '59.99',
        PRODUCT_DESCRIPTION: `
        <p>
            <strong>System:</strong> Nintendo Switch
        </p>


        <p>
            Hit the road with the definitive version of Mario Kart 8 and play anytime, any-where! Race your friends or battle them in a revised battle mode on new and returning battle courses. Play locally in up to 4-player multiplayer in 1080p while playing in TV Mode. Every track from the Wii U version, including DLC, makes a glorious return. Plus, the Inklings appear as all-new guest characters, along with returning favorites, such as King Boo, Dry Bones, and Bowser Jr.!
            System Requirements: Supported Platforms: Nintendo - Switch
            Nintendo account required for game activation and installation
            Nintendo Switch Online membership (sold separately) and Nintendo Account required for online play. Not available in all countries. Internet access required for online features. Terms apply. nintendo.com/switch-online​        
        </p>

        <p>
        <strong>What's included</strong><br>
        Mario Kart 8 Deluxe [Physical]
        </p>
        `
    },
    {
        CATEGORY_SUB: 'xboxGame1',
        PRODUCT_TITLE: 'Halo Infinite',
        PRODUCT_IMG: 'halo1.png',
        RETAIL_PRICE: '39.99',
        PRODUCT_DESCRIPTION: `

        <p>
        <strong>System:</strong> Xbox Series X, Xbox One
        </p>

        <p>
        When all hope is lost and humanity's fate hangs in the balance, the Master Chief is ready to confront the most ruthless foe he's ever faced. The legendary Halo series retunrs with the most expansive Master Chief story yet.
        </p>

        <p>
        Halo: Infinite - Xbox Series X, Xbox One
        The legendary Halo series returns with the most expansive Master Chief campaign yet
        Campaign: When all hope is lost and humanity's fate hangs in the balance, Master Chief is ready to confront the most ruthless foe he's ever faced. Begin anew and step inside the armor of humanity's greatest hero to experience an epic adventure and finally explore the scale of the Halo ring itself
        Multiplayer: Halo's celebrated multiplayer returns! More information coming later this year (requires Xbox Live Gold on console, membership sold separately)
        </p>
        <p>
        Forge: Halo's epic content creation tool is back and more powerful than ever. More information coming later this year
        Cross-Generation Gaming: Halo Infinite provides an amazing experience across the Xbox One and newer family of consoles as well as PC with stunning graphics and world-class cross-platform play. And, on Xbox Series X as well as supported PCs, enjoy enhanced features like up to 4k resolution at 60FPS in campaign and greatly reduced load times creating seamless gameplay that usher in the next generation of gaming        
        </p>

        <p>
        <strong>What's included</strong><br>
        Halo: Infinite - Xbox Series X, Xbox One [Physical]
        </p>
        `
    },
    {
        CATEGORY_SUB: 'xboxGame2',
        PRODUCT_TITLE: 'Forza Horizon 5',
        PRODUCT_IMG: 'frz5.png',
        RETAIL_PRICE: '50.99',
        PRODUCT_DESCRIPTION: `

        <p>
        <strong>System:</strong> Xbox Series X, Xbox One
        </p>

        <p>
        Lead breathtaking expeditions across the vibrant and ever-evolving open world landscapes of Mexico with limitless, fun driving action in hundreds of the world’s greatest cars. Lead breathtaking expeditions across the vibrant and ever-evolving open world landscapes of Mexico with limitless, fun driving action in hundreds of the world’s greatest cars. Explore a world of striking contrast and beauty. Discover living deserts, lush jungles, historic cities, hidden ruins, pristine beaches, vast canyons, and a towering snow-capped volcano. 
        </p>
        
        <p>
        Immerse yourself in a deep campaign with hundreds of challenges that reward you for engaging in the activities you love. Meet new characters and choose the outcomes of their Horizon Story missions. Take on awe-inspiring weather events such as towering dust storms and intense tropical storms as Mexico’s unique, dynamic seasons change the world every week. Keep coming back for new events, challenges, collectibles, and rewards, and new areas to explore. No two seasons will ever be the same. 
        </p>

        <p>
        <strong>What's included</strong><br>
        Forza Horizon 5 [Physical]
        </p>
        `
    },
    {
        CATEGORY_SUB: 'iphone',
        PRODUCT_TITLE: 'Apple iPhone 13 Pro 128GB (Unlocked) - Starlight',
        PRODUCT_IMG: 'iphn13.png',
        RETAIL_PRICE: '999.99',
        PRODUCT_DESCRIPTION: `
        <p>
        iPhone 13 Pro. The biggest Pro camera system upgrade ever. Super Retina XDR display with ProMotion for a faster, more responsive feel. Lightning-fast A15 Bionic chip. Superfast 5G. 1 Durable design and a huge leap in battery life.
        </p>

        <p>
        <strong>Key Features</strong><br>
        6.1-inch Super Retina XDR display with ProMotion for a faster, more responsive feel2<br>
        Cinematic mode adds shallow depth of field and shifts focus automatically in your videos<br>
        Pro camera system with new 12MP Telephoto, Wide, and Ultra Wide cameras; LiDAR Scanner; 6x optical zoom range; macro photography; Photographic Styles, ProRes video, 3 Smart HDR 4, Night mode, Apple ProRAW, 4K Dolby Vision HDR recording<br>
        12MP TrueDepth front camera with Night mode, 4K Dolby Vision HDR recording<br>
        A15 Bionic chip for lightning-fast performance<br>
        Up to 22 hours of video playback - 4<br>
        Durable design with Ceramic Shield<br>
        Industry-leading IP68 water resistance- 5
        </p>

        <p>
        <strong>What's included</strong><br>
        Apple iPhone 13 Pro 128GB (Unlocked) - Starlight<br>
        USB-C to Lightning cable<br>
        Documentation
        </p>
        `
    },
    {
        CATEGORY_SUB: 'samsung',
        PRODUCT_TITLE: 'Galaxy Z Fold3 5G 512GB (Unlocked) - Phantom Black',
        PRODUCT_IMG: 'galaxyz.png',
        RETAIL_PRICE: '1899.99',
        PRODUCT_DESCRIPTION: `
            <p>
            The Samsung Galaxy Z Fold 3 5G, 256GB Phantom Black is finally here! It features an expansive screen: See more and do more with the ultimate folding mobile phone screen that puts a super slim tablet right in your pocket. Nearly Invisible Camera: Take in an incredible uninterrupted view with a true edge-to-edge viewing experience thanks to our first-ever Under Display Camera. 120Hz AMOLED 2X Screen: Everything looks brilliant on this big, beautiful, clear display, offering a mesmerizing and dynamic viewing experience. Multiple Windows Get More Done: Start working smarter not harder with three multi-windows that allow you to schedule, edit, and build presentations in a snap — or take notes with the S Pen. Comes with manufacture's warranty. S pen Not included.

            </p>

            <p>
            Hyper Fast Speed: Live life in the fast lane with built-in 5G connectivity and hyper-fast processing power
            Two Screens, Many Ways to Flex Them: Do more of what you love at the same time with Galaxy Flex Mode; Fold it at any angle and hold it any way you like — or go hands free and not hold it at all
            Charged at the Speed of Life: Stay in charge, not beholden to your charger; With 25W Super Fast Charging*, you can spend less time charging and more time taking care of business.            
            </p>

            <p>
            <strong>What's included</strong><br>
            Galaxy Z Fold3 5G 512GB (Unlocked) - Phantom Black
            </p>
    `
    },
    {
        CATEGORY_SUB: 'ipad',
        PRODUCT_TITLE: 'Apple - 10.9-Inch iPad Air - (4th Generation) with Wi-Fi - 64GB - Sky blue',
        image: 'ipad.png',
        RETAIL_PRICE: '549.99',
        PRODUCT_DESCRIPTION: `

        <p>
        The all-new iPad Air. It's more versatile than ever. The stunning 10.9-inch Liquid Retina display with wide color lets you see photos, videos, and games in vivid, true-to-life detail.¹ It features the A14 Bionic chip with Neural Engine, delivering power and advanced machine learning capabilities to edit a 4K video, create a beautiful presentation, design a 3D model, and do all of these things with ease. And now with support for Magic Keyboard and Apple Pencil (2nd generation)², fast, easy, and secure Touch ID and Apple Pay, a 12MP back camera and 7MP FaceTime HD camera, USB-C connector for charging and accessories, all-day battery life³, and fast Wi-Fi 6. With iPad Air you have the power to bring your ideas to life.
        </p>

        <p>
        Now featuring an all-screen design, the Apple iPad Air has a 10.9" Liquid Retina Display with a 2360 x 1640 resolution at 264 pixels per inch (ppi). The Liquid Retina Display still has all the features you expect, such as P3 wide color gamut coverage, True Tone, anti-reflective and fingerprint-resistant oleophobic coatings, 500 nits of brightness, and support for the 2nd Gen Apple Pencil (sold separately).
        </p>
        
        <p>
        <strong>What's included</strong><br>
        Apple - 10.9-Inch iPad Air - (4th Generation) with Wi-Fi - 64GB - Sky blue<br>
        USB-C Charge Cable (1 meter)<br>
        20W USB-C Power Adapter
        </p>
            `
    },

]

export const auctionDescriptions = products.map(x => {

    let CATEGORY_SUB = x.CATEGORY_SUB.toLowerCase();
    let CATEGORY = '';

    if (CATEGORY_SUB.includes('bidpack')) {
        CATEGORY = 'bidpack'
    } else if (CATEGORY_SUB.includes('giftcard')) {
        CATEGORY = 'giftCard'
    } else if (CATEGORY_SUB.includes('xbox')) {
        CATEGORY = 'xbox'
    } else if (CATEGORY_SUB.includes('playstation')) {
        CATEGORY = 'playstation'
    } else if (CATEGORY_SUB.includes('nintendo')) {
        CATEGORY = 'nintendo'
    } else if (CATEGORY_SUB.includes('iphone') || CATEGORY_SUB.includes('ipad')) {
        CATEGORY = 'apple'
    } else if (CATEGORY_SUB.includes('samsung')) {
        CATEGORY = 'samsung'
    }

    const obj = {
        ...x,
        CATEGORY
    }

    return obj

})

export const categoryTypes = Object.freeze({
    all: 'all',
    apple: 'apple',
    bidpack: 'bidpack',
    giftCard: 'giftCard',
    xbox: 'xbox',
    playstation: 'playstation',
    nintendo: 'nintendo',
    samsung: 'samsung',
    xbox: 'xbox'
})


export const createCategoryArr = () => {
    let categories = []

    for (const key in categoryTypes) {

        const CATEGORY_SUB = categoryTypes[key]
        const CATEGORY_TITLE = categoryText[key]

        const obj = {
            CATEGORY_SUB,
            CATEGORY_TITLE
        }

        categories.push(obj);

    }

    return categories

}