import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router';
import { actionMemberPop } from '../../reducers/user';
import { SlideMenuRightBox } from '../../style/main';
import { actionCloseMenus } from '../../reducers/layout';
import { MY_ACCOUNT_TAB_KEYS } from '../MyAccount';

export const myAccountMenuText = [
    {
        name: 'Add Bids',
        click: 'addBids'
    },
    {
        name: 'My account',
        url: '/myaccount',
    },
    {
        name: 'Shipping Info',
        url: `/myaccount?${MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO}=true`,
    },
    {
        name: 'Winning Auctions',
        url: `/myaccount?${MY_ACCOUNT_TAB_KEYS.WINNING_AUCTIONS}=true`,
    },
    {
        name: 'Sign out',
        click: 'logOut'
    }
]


const MenuMyAccountTab = React.memo(({ name, arrow, click, url }) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const onClick = useCallback(() => {

        if (click === 'addBids') {

            dispatch(actionMemberPop('addCredits'));

        } else if (click === 'logOut') {

            const auth = getAuth();
            signOut(auth)

        } else if (url) {

            navigate(url);

        }

    }, [click, url]);

    return (
        <li className='row' onClick={onClick}>
            <div>{name}</div>
            {arrow &&
                <svg className='arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z" />
                </svg>
            }
        </li>
    );
})

const MenuMyAccount = React.memo(() => {

    const dispatch = useDispatch();

    const myAccountMenu = useSelector((state) => state.layout.myAccountMenu)
    const dealDropdownOpen = useSelector((state) => state.layout.dealDropdownOpen)
    const menuOpen = useSelector((state) => state.layout.menuOpen)

    const closeMenu = useCallback(() => {

        dispatch(actionCloseMenus({ myAccountMenu: true }))

    }, []);

    useEffect(() => {

        const removeEventListener = () => {
            window.removeEventListener("click", closeMenu)
        }

        removeEventListener()

        if (!myAccountMenu) {

            dispatch(actionCloseMenus({ myAccountMenu: true }))

        } else {

            window.addEventListener("click", closeMenu)
        }

        return removeEventListener

    }, [myAccountMenu]);

    useEffect(() => {

        if (menuOpen || dealDropdownOpen) {

            dispatch(actionCloseMenus({ myAccountMenu: true }))

        }
    }, [menuOpen, dealDropdownOpen]);

    return (
        <SlideMenuRightBox className={`column ${myAccountMenu ? 'active' : ''}`}>

            {myAccountMenuText.map(x => <MenuMyAccountTab key={x.name} {...x} />)}

        </SlideMenuRightBox>
    );
})

export default MenuMyAccount;