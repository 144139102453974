import React, { useEffect, useCallback, useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { SuccessCheckBlurb } from '../../style/main';

const initialState = {
    loading: false,
    emailSent: false
}

const Account = React.memo(() => {

    const email = useSelector((state) => state.user.email)

    const [emailState, setEmailState] = useState(initialState);

    const resetPassword = useCallback(() => {

        if (emailState.loading) {

            return

        }

        setEmailState(x => ({
            ...x,
            loading: true
        }))

        const auth = getAuth();

        sendPasswordResetEmail(auth, email)
            .then(() => {

                setEmailState(x => ({
                    ...x,
                    emailSent: true
                }))

            })

    }, [email, emailState.loading, setEmailState]);

    useEffect(() => {

        if (emailState.emailSent) {

            setTimeout(() => {

                setEmailState(initialState)

            }, 5000)
        }

    }, [emailState.emailSent]);

    return (
        <div className='accountBox column'>

            <div className="infoRow resetPassword column">

                <div className="resetPasswordRow row">

                    <div className="infoRowLeft">
                        {DEFAULT_TEXT['page.myaccount.tab.account.resetPassword']}
                    </div>

                    <div className="infoRowRight column">

                        <div className="smallBtn" onClick={resetPassword}>{DEFAULT_TEXT['button.myaccount.tab.account.sendReset']}</div>

                    </div>

                </div>

                {emailState.emailSent &&

                    <SuccessCheckBlurb className='row'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path></svg>

                        <div className="text">
                            {DEFAULT_TEXT['page.myaccount.tab.account.pleaseCheckYourEmail']}
                        </div>
                    </SuccessCheckBlurb>

                }

            </div>

        </div>
    );
})

export default Account;