import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCartMode } from '../providers/CartMode';
import { usePages } from '../providers/PagesProvider';
import { Auctions } from '../style/main';
import FeatureBox from './snippet/FeatureBox';
import Pages from './Pages';
import Breadcrumbs from './snippet/BreadcrumbsFilter';
import AuctionSingle from './AuctionSingle';
import { useLocation, useSearchParams } from 'react-router-dom';
import IntroVideo from './snippet/IntroVideo';
export const auctionsPerPage = 48;

const Homepage = React.memo(() => {

    const location = useLocation();

    const { cartMode } = useCartMode();

    const breadcrumbRef = useRef(null)

    let auctions = useSelector((state) => state.auctions.auctions)

    let [searchParams, setSearchParams] = useSearchParams();

    const filters = searchParams?.get('filter')?.split(' ') || [];

    if (filters.length) {
        const filtered = auctions.filter(({ BRAND }) => filters.includes(BRAND))

        if (filtered.length) {
            auctions = filtered;
        }
    }

    const breadcrumbTotal = auctions?.length;

    const { currentPage } = usePages();
    const start = (currentPage - 1) * auctionsPerPage;
    const end = start + auctionsPerPage;
    const auctionsPage = auctions.slice(start, end)

    const totalPages = Math.ceil(auctions?.length / auctionsPerPage);

    return (
        <>

            {!cartMode &&
                <IntroVideo />
            }

            <div className="wrap">

                <Breadcrumbs breadcrumbTotal={breadcrumbTotal} breadcrumbRef={breadcrumbRef} />

                <Auctions className='row'>
                    {!auctionsPage?.length ?
                        [...Array(9).keys()].map(x => <AuctionSingle key={x} loading={true} />)
                        :
                        auctionsPage.map(x => <AuctionSingle key={x.id} {...x} />)
                    }
                </Auctions>

                <Pages totalPages={totalPages} />
            </div>

        </>
    );
})

export default Homepage;