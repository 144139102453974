export const BRAND_LIST = Object.freeze({
    'APPLE': 'Apple',
    'BIDPACK': 'Bid Packs',
    'GIFT_CARD': 'Gift Cards',
    'NINTENDO': 'Nintendo',
    'PLAYSTATION': 'PlayStation',
    'SAMSUNG': 'Samsung',
    'XBOX': 'Xbox'
})

export const BRAND_ALL_TEXT = {
    US: 'All'
}