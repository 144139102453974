import React, { useEffect, useCallback, useState, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_TEXT } from '../content';
import { CURRENCY } from '../content/currency';
import { helpTopics } from '../content/help';
import { useCartMode } from '../providers/CartMode';
import { HelpBox } from '../style/main';
import { createHelpGroups, createHelpTitleId } from './helpers';
import LdsRing from './snippet/LdsRing';

const scrollToTop = divId => {
    setTimeout(() => {

        const id = document.getElementById(divId)
        const top = id.offsetTop - 10;

        window.scrollTo({
            top,
            left: 0
        });

    }, 2)
}


const TopicH2 = React.memo(({ title, topicKey, selected, setSelected }) => {

    const onClick = useCallback(() => {

        // contactFormis when the contact form is opened in the footer
        if (selected === topicKey || selected === 'contactForm') {

            setSelected(false)

        } else {

            setSelected(topicKey)

            scrollToTop(topicKey)

        }

    }, [topicKey, selected]);

    return (
        <h2 className='row' onClick={onClick}>

            <span>{title}</span>
            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path>
            </svg>

        </h2>
    );
})


const NextTopic = React.memo(({ title, setSelected }) => {

    const index = helpTopics.findIndex(x => x.title === title)

    const nextTitle = helpTopics[index + 1]?.title;

    if (!nextTitle) {
        return null
    }

    const onClick = useCallback(() => {

        const id = createHelpTitleId(nextTitle);

        setSelected(id);
        scrollToTop(id);

    }, []);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 0
        });

    }, []);

    return (

        <div className="nextTopic row" onClick={onClick}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /></svg>

            <div className="nextTopicText row">
                <div className='nextFirst'>{DEFAULT_TEXT['page.help.nextTopic']}</div>
                <div className='nextUp'>{nextTitle}</div>
            </div>

        </div>

    );
})

const validateEmail = ({ email }) => {
    const emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

    return emailRegex.test(email)
}

const Help = React.memo(() => {

    const { cartMode } = useCartMode();

    let [searchParams, setSearchParams] = useSearchParams();

    const [selected, setSelected] = useState(false);
    const creditsPackages = useSelector((state) => state.auctions.creditsPackages)

    let bidString = '';

    if (creditsPackages?.length) {

        creditsPackages.forEach(x => bidString += `<li><strong>${x.amount} Bids</strong> - ${CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}${x.cost} - ${(x.cost / x.amount) >= 1 ? CURRENCY[process.env.REACT_APP_ISO_CODE].dollar : ''}${(x.cost / x.amount).toFixed(2)}${(x.cost / x.amount) < 1 ? CURRENCY[process.env.REACT_APP_ISO_CODE].cents : ''} each</li>`)

        bidString = `<ol>${bidString}</ol>`

    }

    const [errors, setErrors] = useState({

        email: false,
        message: false

    });

    const timeoutRef = useRef(null)

    const [loading, setLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const submitMessage = useCallback((e) => {

        e.preventDefault();


        if (loading) {
            return
        }

        const email = document.getElementById('email')
        const message = document.getElementById('message')

        const emailValue = email.value
        const messageValue = message.value

        let error = {
            email: false,
            message: false
        };

        if (!emailValue.length || !validateEmail({ email: emailValue })) {
            error.email = true;
        }

        if (!messageValue.length) {
            error.message = true;
        }

        setErrors(error)

        if (!error.email && !error.message) {

            setLoading(true)

            return fetch(process.env.REACT_APP_URL_CONTACT_US, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: emailValue,
                    message: messageValue
                }),
            })
                .then(response => response.json())
                .then(resp => {

                    if (resp?.success) {

                        setLoading(false)

                        email.value = '';
                        message.value = '';

                        setMessageSent(true)

                    }

                })

        }


    }, [loading]);

    useLayoutEffect(() => {

        const section = searchParams.get("section");
        const id = document.getElementById(section)

        if (section && id) {

            if (section === 'contact') {

                // AUTO OPEN THE CONTACT FORM
                setSelected('contactForm')

            } else {
                setSelected(false)
            }

            scrollToTop(section)

        } else {

            window.scrollTo({
                top: 0,
                left: 0
            });

        }

    }, [searchParams]);

    useEffect(() => {

        clearTimeout(timeoutRef.current)

        if (messageSent) {

            timeoutRef.current = setTimeout(() => {

                // POSSIBILITY TO HIDE THE MESSAGE AFTER
                setMessageSent(false)

            }, 8000)

        }

        return () => {

            clearTimeout(timeoutRef.current)

        }

    }, [messageSent]);

    return (

        <div className='wrap column'>
            <HelpBox className='column'>

                {!cartMode &&
                    <>
                        <h1 className='row'>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ><path d="M 7.5 1 C 3.917969 1 1 3.917969 1 7.5 C 1 11.082031 3.917969 14 7.5 14 C 11.082031 14 14 11.082031 14 7.5 C 14 3.917969 11.082031 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 4.457031 13 2 10.542969 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 7.558594 4 C 6.347656 4.070313 5.359375 5.085938 5.359375 6.320313 L 6.359375 6.320313 C 6.359375 5.507813 7.0625 4.871094 7.921875 5.019531 C 8.535156 5.125 9.019531 5.726563 9 6.359375 C 8.984375 6.914063 8.660156 7.265625 8.183594 7.476563 C 7.886719 7.609375 7.605469 7.734375 7.367188 7.984375 C 7.128906 8.234375 7 8.605469 7 9 L 8 9 C 8 8.773438 8.03125 8.738281 8.09375 8.671875 C 8.152344 8.609375 8.316406 8.511719 8.585938 8.390625 C 9.332031 8.066406 9.972656 7.351563 10 6.390625 C 10.035156 5.253906 9.21875 4.226563 8.089844 4.03125 C 7.910156 4.003906 7.730469 3.992188 7.558594 4 Z M 7 10 L 7 11 L 8 11 L 8 10 Z" /></svg>
                            <span>{DEFAULT_TEXT['page.help.title']}</span>

                        </h1>

                        <p className='helpBlurb'>
                            {DEFAULT_TEXT['page.help.intro']}
                        </p>
                    </>
                }

                <div className="helpContent column">

                    {Object.entries(createHelpGroups()).map(([key, value], count) => (
                        <React.Fragment key={key}>
                            {(cartMode && key === 'contact') || !cartMode ?

                                <div className='groupingBox column'>

                                    <div className="heading row" id={key}>

                                        {key === 'bidding' ?

                                            <img src="/img/credit.svg" className='credit' />

                                            : key === 'productsAndShipping' ?
                                                <img src="/img/shipping-free.svg" className='shipping' />

                                                : key === 'contact' ?
                                                    <img src="/img/support-chat.svg" className='contact' />

                                                    :

                                                    <img src="/img/info.svg" className='info' />
                                        }


                                        <span>{value.title}</span>

                                    </div>

                                    <div className="topics column">

                                        {value.topics.map((x, i) =>

                                            <div className={`topic column ${(selected === createHelpTitleId(x.title)) || (x.contactForm && selected === 'contactForm') ? 'active' : ''}`} id={createHelpTitleId(x.title)} key={x.title}>

                                                <TopicH2 {...x} topicKey={createHelpTitleId(x.title)} setSelected={setSelected} selected={selected} />

                                                <div className="topicBody column">

                                                    <div className="text column" dangerouslySetInnerHTML={{
                                                        __html:
                                                            `
                                                    ${(x.creditsPackages && creditsPackages?.length) ? x.html.replace('INSERT_QUANTITIES', creditsPackages.length) : x.html}

                                                    ${(x.creditsPackages && bidString?.length) ? bidString : ''}
                                                `

                                                    }}></div>



                                                    {x.contactForm &&

                                                        <form className='contactForm column' onSubmit={submitMessage}>

                                                            <div className="field column">

                                                                <label>{DEFAULT_TEXT['page.help.contact.email.placeholder']}</label>
                                                                <input id="email" type="text" />

                                                                {errors.email && <div className="error">{DEFAULT_TEXT['page.help.contact.error.email']}</div>}

                                                            </div>

                                                            <div className="field column">

                                                                <label>{DEFAULT_TEXT['page.help.contact.message.placeholder']}</label>
                                                                <textarea id="message"></textarea>

                                                                {errors.message && <div className="error">{DEFAULT_TEXT['page.help.contact.error.message']}</div>}

                                                            </div>

                                                            {messageSent && <div className='messageSent row'>

                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path></svg>

                                                                <div className="textRight">{DEFAULT_TEXT['page.help.contact.success']}</div>

                                                            </div>}

                                                            <button>
                                                                {loading ?
                                                                    <LdsRing />
                                                                    :
                                                                    DEFAULT_TEXT['button.contact.submit']
                                                                }
                                                            </button>

                                                        </form>

                                                    }


                                                    <NextTopic title={x.title} setSelected={setSelected} />

                                                </div>

                                            </div>

                                        )}

                                    </div>

                                </div>
                                :
                                ''
                            }
                        </React.Fragment>
                    ))}

                </div>

            </HelpBox>

        </div>

    );
})

export default Help;