import { createSlice, current } from '@reduxjs/toolkit'


const initialState = {
    uid: false,
    credits: 0,
    displayName: false,
    email: false,
    avatar: 0,
    memberPop: false,
    bidsPurchased: false,
    userLoaded: false,
    userNotFound: false,
    addressInfo: false,
    paymentMethods: false,
    cartPaymentIntent: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        actionUserLoaded: (state, action) => {

            const { uid, credits, displayName, email, avatar } = action.payload;

            state.uid = uid;
            state.credits = parseInt(credits) || 0;
            state.displayName = displayName;
            state.email = email;
            state.avatar = parseInt(avatar) || 0;
            state.userLoaded = true;

            let addressInfo = action?.payload?.addressInfo;

            if (addressInfo) {
                addressInfo = JSON.parse(addressInfo)
                state.addressInfo = addressInfo;
            }

        },
        actionUserNotFound: (state, action) => {

            // USED WHEN SIGNIN IN REDIS DOES NOT EXIST
            state.userNotFound = action.payload;
            state.userLoaded = true;

        },
        actionUserDoesNotExist: (state, action) => {

            const obj = {
                ...initialState,
                userLoaded: true
            }

            return obj

        },
        actionCredits: (state, action) => {

            state.credits = parseInt(action.payload);

        },
        actionDeductCredits: (state, action) => {

            const credits = current(state)?.credits;

            state.credits = credits - 1;

        },
        actionUpdateAvatar: (state, action) => {

            const avatar = current(state)?.avatar;

            state.avatar = action.payload;

            if (avatar) {
                // AVATAR EXISTS, CLOSE THE BOX, ONLY AN UPDATE
                state.memberPop = false;
            } else {
                // AVATAR DOES NOT EXIST AFTER SIGNUP, GO TO ADD CREDITS
                state.memberPop = 'addCredits';
            }

        },
        actionUpdateDisplayName: (state, action) => {

            // UPDATE THE DISPLAY NAME AND CLOSE THE MEMBER POP
            state.displayName = action.payload;
            state.memberPop = false;

        },
        actionMemberPop: (state, action) => {

            state.memberPop = action.payload;

        },
        actionBidsPurchased: (state, action) => {

            state.memberPop = 'bidsAdded';
            state.bidsPurchased = action.payload;

        },
        actionPaymentMethods: (state, action) => {

            state.paymentMethods = action.payload;

        }
    },
})

export const { actionCredits, actionDeductCredits, actionUserLoaded, actionUserDoesNotExist, actionUpdateAvatar, actionMemberPop, actionBidsPurchased, actionUpdateDisplayName, actionPaymentMethods, actionUserNotFound } = userSlice.actions

export default userSlice.reducer