import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionToggleMenu } from '../../reducers/layout';
import { scrollTop } from '../helpers';

const MenuIcon = React.memo(() => {

    const dispatch = useDispatch();

    const menuOpen = useSelector((state) => state.layout.menuOpen)

    const menuIconClick = useCallback(() => {

        scrollTop()
        dispatch(actionToggleMenu())

    }, []);

    return (
        <div className={`menuIcon row ${menuOpen ? 'active' : ''}`} id="menuIcon" onClick={menuIconClick}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24" className='open'>
                <path d="M0,5v2h20V5H0z M0,11v2h20v-2H0z M0,17v2h20v-2H0z" />
            </svg>


            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='close'>
                <path d="M4.4,2.8L2.8,4.4l7.6,7.6l-7.6,7.6l1.7,1.7l7.6-7.6l7.6,7.6l1.7-1.7L13.7,12l7.6-7.6l-1.7-1.7L12,10.3L4.4,2.8z" />
            </svg>

        </div>
    );
})

export default MenuIcon;