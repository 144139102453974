import React from 'react';
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router';

const AuthPublic = React.memo(({ children }) => {

    const uid = useSelector((state) => state.user.uid)

    if (uid) {
        return <Navigate to="/" />;
    }

    return children
})

export default AuthPublic;