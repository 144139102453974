import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from "react-router-dom";
import MemberPop from './MemberPop';
import TabBox from './myaccount/TabBox';
import { DEFAULT_TEXT } from '../content';
import { actionMemberPop, actionPaymentMethods } from '../reducers/user';
import { MyAccountBox } from '../style/main';
import { getAccessToken } from './helpers';
import Avatar from './snippet/Avatar';

export const MY_ACCOUNT_TAB_KEYS = Object.freeze({
    PROFILE: 'PROFILE',
    SHIPPING_INFO: 'SHIPPING_INFO',
    WINNING_AUCTIONS: 'WINNING_AUCTIONS',
    ORDER_HISTORY: 'ORDER_HISTORY',
    BID_HISTORY: 'BID_HISTORY',
    PAYMENT_METHODS: 'PAYMENT_METHODS',
    ACCOUNT: 'ACCOUNT'
})

const myAccountTabs = Object.freeze({
    [MY_ACCOUNT_TAB_KEYS.PROFILE]: DEFAULT_TEXT['page.myaccount.tab.profile'],
    [MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO]: DEFAULT_TEXT['page.myaccount.tab.shippingInfo'],
    [MY_ACCOUNT_TAB_KEYS.WINNING_AUCTIONS]: DEFAULT_TEXT['page.myaccount.tab.winningAuctions'],
    [MY_ACCOUNT_TAB_KEYS.ORDER_HISTORY]: DEFAULT_TEXT['page.myaccount.tab.orderHistory'],
    [MY_ACCOUNT_TAB_KEYS.BID_HISTORY]: DEFAULT_TEXT['page.myaccount.tab.bidHistory'],
    [MY_ACCOUNT_TAB_KEYS.PAYMENT_METHODS]: DEFAULT_TEXT['page.myaccount.tab.paymentMethods'],
    [MY_ACCOUNT_TAB_KEYS.ACCOUNT]: DEFAULT_TEXT['page.myaccount.tab.account']
})

const scrollToTop = ref => {
    setTimeout(() => {

        const top = ref.offsetTop - 10;

        window.scrollTo({
            top,
            left: 0
        });

    }, 10)
}


const MyAccount = React.memo(() => {

    const paymentMethodsRef = useRef(null)
    const shippingInfoRef = useRef(null)
    const winningAuctionsRef = useRef(null)

    let [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const displayName = useSelector((state) => state.user.displayName)
    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)

    const [tab, setTab] = useState(false);

    useEffect(() => {

        if (uid) {

            const addPaymentMethodParam = searchParams.get(MY_ACCOUNT_TAB_KEYS.PAYMENT_METHODS);
            const shippingInfoParam = searchParams.get(MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO);
            const winningAuctionsParam = searchParams.get(MY_ACCOUNT_TAB_KEYS.WINNING_AUCTIONS);

            if (addPaymentMethodParam) {

                // OPEN PAYMENT METHODS BOX, AND SCROLL IT TO THE TOP OF THE WINDOW

                navigate(window.location.pathname, { replace: true });

                setTab(MY_ACCOUNT_TAB_KEYS.PAYMENT_METHODS)

                scrollToTop(paymentMethodsRef.current)

            } else if (shippingInfoParam) {

                navigate(window.location.pathname, { replace: true });

                setTab(MY_ACCOUNT_TAB_KEYS.SHIPPING_INFO)

                scrollToTop(shippingInfoRef.current)

            } else if (winningAuctionsParam) {

                navigate(window.location.pathname, { replace: true });

                setTab(MY_ACCOUNT_TAB_KEYS.WINNING_AUCTIONS)

                scrollToTop(winningAuctionsRef.current)

            }


        }

    }, [uid, searchParams]);



    useEffect(() => {

        // IF USER HAS LOADED AND DOES NOT EXIST, SEND THEM TO SIGNIN
        if (userLoaded && !uid) {

            navigate(DEFAULT_TEXT['url.signin'], { replace: true });

        }

    }, [uid, userLoaded]);

    return (
        <div className='wrap column'>
            <MyAccountBox className='column'>


                <Avatar type='myAccount1' />

                <div className={`displayName ${!displayName ? 'loading' : ''}`}>
                    {displayName ? displayName : <span>loading</span>}
                </div>

                <div className="tabsBox column">

                    {Object.keys(myAccountTabs).map((key, i) =>
                        <TabBox key={key} keyVar={key} title={myAccountTabs[key]} setTab={setTab} tab={tab} paymentMethodsRef={paymentMethodsRef} shippingInfoRef={shippingInfoRef} winningAuctionsRef={winningAuctionsRef} scrollToTop={scrollToTop} />
                    )}

                </div>


            </MyAccountBox>
        </div>
    );
})

export default MyAccount;