import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createPrice, getAccessToken } from '../helpers';
import { DEFAULT_TEXT } from '../../content';
import { Link } from 'react-router-dom';
import { CURRENCY } from '../../content/currency';
import { auctionDescriptions } from '../../content/products';


const totalPerPage = 25;

const BidHistoryRow = React.memo(({ id, SKU, priceCurrent, TITLE }) => {

    const ISO_CODE = process.env.REACT_APP_ISO_CODE;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE.toUpperCase();

    const filename = `${LANGUAGE}_${SKU}.png`
    let filepath = `/img/products/${ISO_CODE}/small/${filename}`

    return (
        <Link to={`/auctions/${id}`} className="bid row">
            <div className="leftInfo row">
                <img src={filepath} alt={TITLE} />
                <div className="title">{TITLE}</div>
            </div>
            <div className="price row">
                <div className="text">{DEFAULT_TEXT['page.myaccount.tab.bidHistory.bidPrice']}</div>
                {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{createPrice(priceCurrent)}
            </div>
        </Link>
    );
})

const Page = React.memo(({ pageNumber, page, all, setBidHistory, bidHistoryRef }) => {

    pageNumber = pageNumber + 1;

    const onClick = useCallback(() => {

        const sliceStart = (pageNumber - 1) * totalPerPage;
        const sliceEnd = sliceStart + totalPerPage;

        const currentPage = [...all].slice(sliceStart, sliceEnd);

        const scrollTop = bidHistoryRef.current.offsetTop;

        setTimeout(() => {

            setBidHistory(x => ({
                ...x,
                page: pageNumber,
                currentPage
            }))

        }, 1)

        window.scroll(0, scrollTop)



    }, [pageNumber, setBidHistory, all]);

    return (
        <li className={`${(pageNumber === page) ? 'active' : ''}`} onClick={onClick}>{pageNumber}</li>
    )
})



const BidHistory = React.memo(({ bidHistoryRef }) => {

    const [bidHistory, setBidHistory] = useState({
        currentPage: [],
        all: [],
        page: 1,
        totalPages: 0
    });

    const uid = useSelector((state) => state.user.uid)

    useEffect(() => {

        if (!uid) {
            return
        }

        getAccessToken().then(token => {

            return fetch(process.env.REACT_APP_URL_BID_HISTORY, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken: token }),
            })
                .then(response => response.json())
                .then(bids => {

                    const totalPages = Math.ceil(bids.length / totalPerPage)
                    const currentPage = bids.slice(0, totalPerPage);

                    setBidHistory({
                        all: bids,
                        currentPage,
                        page: 1,
                        totalPages
                    })

                })

        })

    }, [uid]);

    return (
        <>
            <div className="bidHistory column" ref={bidHistoryRef}>

                {bidHistory?.currentPage?.length ?
                    bidHistory.currentPage.map((x, i) =>
                        <BidHistoryRow key={i} {...x} />
                    )

                    :
                    <div className='noHistory'>{DEFAULT_TEXT['page.myaccount.tab.bidHistory.noHistory']}</div>

                }

                {(bidHistory?.totalPages > 1) ?
                    <ul className="pages row">
                        {[...Array(bidHistory.totalPages).keys()].map(pageNumber => <Page key={pageNumber} pageNumber={pageNumber} setBidHistory={setBidHistory} {...bidHistory} bidHistoryRef={bidHistoryRef} />)}
                    </ul>
                    :
                    ''
                }

            </div>
        </>
    );
})

export default BidHistory;