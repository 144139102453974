import React, { useState, useCallback, useRef } from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT, DISPLAY_NAME_MAX_CHARACTERS } from '../../content';
import { actionUpdateDisplayName } from '../../reducers/user';
import { displayNameRules, getAccessToken } from '../helpers';
import { MemberPopContext } from '../MemberPop';

const EditDisplayName = React.memo(() => {

    const dispatch = useDispatch();

    const [focusInput, setFocusInput] = useState(false)

    const uid = useSelector((state) => state.user.uid)

    const { submitEditDisplayRef, setLoading } = useContext(MemberPopContext)

    const displayName = useRef(null)

    const [errors, setErrors] = useState({
        displayName: false,
        base: false
    });

    const onFocus = useCallback((e) => {
        setFocusInput(true)
    }, [setFocusInput]);

    const onBlur = useCallback((e) => {
        setFocusInput(false)
    }, [setFocusInput]);

    const handleSubmit = useCallback((e) => {

        e.preventDefault();

        const valueDisplayName = displayName.current.value;

        const displayNameError = displayNameRules(valueDisplayName)

        setErrors({
            displayName: displayNameError
        })

        if (!displayNameError) {

            setLoading(true)

            getAccessToken().then(accessToken => {

                const obj = {
                    accessToken,
                    displayName: valueDisplayName
                }

                return fetch(process.env.REACT_APP_URL_UPDATE_DISPLAY_NAME, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(obj),
                })
                    .then(response => response.json())
                    .then(resp => {

                        setLoading(false)

                        if (resp?.error) {

                            setErrors({
                                base: true
                            })

                        } else {

                            // UPDATE THE DISPLAY NAME AND CLOSE THE MEMBER POP
                            dispatch(actionUpdateDisplayName(valueDisplayName));

                        }

                    })

            })
        }


    }, [uid]);

    return (
        <form className="editDisplayNameBox column" onSubmit={handleSubmit}>

            <div className="field column">
                <div className={`inputBox row ${focusInput ? 'active' : ''}`}>

                    <label htmlFor="displayName">{DEFAULT_TEXT['member.pop.editDisplayName.placeholder']}</label>
                    <input type="text" name="displayName" placeholder={DEFAULT_TEXT['member.pop.editDisplayName.placeholder']} ref={displayName} onFocus={onFocus} onBlur={onBlur} maxLength={DISPLAY_NAME_MAX_CHARACTERS} onChange={(e) => e.target.value = e.target.value.replace(/\s+/g, '')} />

                </div>

                {errors?.displayName && <div className="error">{errors.displayName}</div>}

            </div>

            {errors?.base && <div className="error base" dangerouslySetInnerHTML={{ __html: DEFAULT_TEXT['form.displayName.error.base'] }}></div>}

            <button ref={submitEditDisplayRef}>Hidden</button>

        </form>
    );
})

export default EditDisplayName;