import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderBox } from '../../style/main';
import Logo from '../snippet/Logo';
import Menu from './Menu';
import MenuMobileIcon from './MenuMobileIcon';
import AvatarRight from './AvatarRight';
import MenuMyAccount from './MenuMyAccount';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import HeaderCredits from './HeaderCredits';
import ShoppingFrom from './shoppingFrom/ShoppingFrom';
import CornerPiece from './CornerPiece';

const Header = React.memo(() => {

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)

    return (
        <>
            <HeaderBox className="row">

                <div className="leftSideHeader row">
                    <MenuMobileIcon />
                    <Logo />
                    <Menu />
                </div>

                {userLoaded &&
                    <div className="rightSideHeader row">
                        {uid ?
                            <>
                                <HeaderCredits />
                                <AvatarRight />
                                <MenuMyAccount />

                            </>
                            :
                            <>
                                <Link to={DEFAULT_TEXT['url.signin']} className='signinLink row'>
                                    <div>{DEFAULT_TEXT['button.signin']}</div>
                                </Link>
                                <Link to={DEFAULT_TEXT['url.signup']} className="btn row">
                                    <div>{DEFAULT_TEXT['button.join']}</div>
                                </Link>
                            </>
                        }
                    </div>
                }

                <CornerPiece />

            </HeaderBox>

            <ShoppingFrom />
        </>
    );
})

export default Header;