import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionCreditPackagesText } from '../reducers/auctions';

const TextProvider = React.memo(({ children }) => {

    const dispatch = useDispatch();

    useEffect(() => {

        fetch(process.env.REACT_APP_URL_TEXT)
            .then(response => response.json())
            .then((resp) => {

                const creditsPackages = resp?.creditsPackages;

                if (creditsPackages) {
                    dispatch(actionCreditPackagesText(creditsPackages));
                }
            })

    }, []);

    return (
        <>
            {children}
        </>
    );
})

export default TextProvider;