import { createSlice, current } from '@reduxjs/toolkit'


export const auctionSlice = createSlice({
    name: 'auctions',
    initialState: {
        auctions: [],
        sold: [],
        creditsPackages: false,
        brands: []
    },
    reducers: {
        actionSetAuctions: (state, action) => {

            const auctions = action.payload;

            //GET BRANDS DROPDOWN COUNT
            const BRANDS_COUNTS = {
                ALL: 0
            }

            auctions.forEach(({ BRAND }) => {
                if (BRANDS_COUNTS[BRAND] >= 0) {
                    BRANDS_COUNTS[BRAND]++
                } else {
                    BRANDS_COUNTS[BRAND] = 1;
                }

                BRANDS_COUNTS.ALL++
            })

            let brands = [];

            for (const key in BRANDS_COUNTS) {
                const count = BRANDS_COUNTS[key]
                brands.push({
                    brand: key,
                    count
                })
            }

            brands.sort((a, b) => a.brand.localeCompare(b.brand))

            state.auctions = auctions;
            state.brands = brands;


        },
        actionCreditPackagesText: (state, action) => {

            state.creditsPackages = action.payload;

        },
        actionCountdowns: (state, action) => {

            let auctions = current(state?.auctions);

            let { countdowns, soldAuctionIds } = action.payload;

            auctions = auctions.map(auction => {

                const { id } = auction;

                const countdown = countdowns.find(countdown => countdown.id === id)?.countdown;

                const obj = {
                    ...auction
                }

                if (soldAuctionIds?.includes(id)) {

                    obj.countdown = 0;

                } else if (countdown >= 0) {

                    obj.countdown = countdown;

                }

                return obj

            })

            state.auctions = auctions;

        },
        actionJustSold: (state, action) => {

            // MARK ACTIVE AUCTIONS AS SOLD
            const soldAuctionIds = action.payload;

            let auctions = current(state?.auctions);

            auctions = auctions.map(auction => {

                const { id } = auction;

                const obj = {
                    ...auction
                }

                if (soldAuctionIds?.includes(id)) {

                    obj.sold = true;

                }

                return obj

            })

            state.auctions = auctions;


        },
        actionAuctionsBids: (state, action) => {

            let auctions = current(state?.auctions);
            const bidAuctions = action.payload;

            auctions = auctions.map(auction => {

                let { id, priceCurrent } = auction;

                const bidAuction = bidAuctions.find(x => x.id === id);

                const obj = {
                    ...auction
                }

                if (bidAuction) {

                    obj.priceCurrent = parseInt(priceCurrent) + 1;
                    obj.history = bidAuction.history;

                    if (!bidAuction.noBoost) {
                        obj.countdown = 10;
                    }

                }


                return obj

            })

            state.auctions = auctions;

        }
    },
})

export const { actionSetAuctions, actionCreditPackagesText, actionAuctionsBids, actionCountdowns, actionJustSold } = auctionSlice.actions

export default auctionSlice.reducer