import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { actionPaymentMethods } from '../../reducers/user';
import { getAccessToken } from '../helpers';

const brands = ['visa', 'mastercard', 'amex', 'diners', 'discover', 'elo', 'jcb', 'unionpay']

const Card = React.memo(({ id, brand, last4, exp_month, exp_year, zipCode }) => {

    const dispatch = useDispatch();

    const expireMonthRef = useRef(null);
    const expireYearRef = useRef(null);
    const zipCodeRef = useRef(null);

    const [error, setError] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updated, setUpdated] = useState(false);

    const uid = useSelector((state) => state.user.uid)
    const paymentMethods = useSelector((state) => state.user.paymentMethods)

    const years = [...Array(15).keys()].map(count => {
        const startYear = new Date().getFullYear();

        return startYear + count
    })

    const months = [...Array(12).keys()].map(count => count + 1)

    if (!brands.includes(brand)) {

        brand = 'blank';

    }

    useEffect(() => {

        if (updated) {

            setTimeout(() => {

                setUpdating(false)
                setUpdated(false)

            }, 1000)

        }

    }, [updated]);

    const removeCard = useCallback(() => {

        if (!uid) {
            return
        }

        getAccessToken().then(token => {

            const payments = paymentMethods.filter(x => x.id !== id);

            dispatch(actionPaymentMethods(payments));


            return fetch(process.env.REACT_APP_URL_REMOVE_CARD, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken: token, cardId: id }),
            })

        })

    }, [uid, id, paymentMethods]);

    const updateCard = useCallback(() => {

        if (!uid || updating) {
            return
        }

        getAccessToken().then(token => {

            const exp_month = expireMonthRef.current.value;
            const exp_year = expireYearRef.current.value;
            const postal_code = zipCodeRef.current.value;

            if (postal_code.length < 5) {
                setError(DEFAULT_TEXT['page.myaccount.tab.paymentMethods.error.zipCode'])
                return
            }

            setError(false)
            setUpdating(true)

            return fetch(process.env.REACT_APP_URL_UPDATE_CARD, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken: token, cardId: id, exp_month, exp_year, postal_code }),
            })
                .then(response => response.json())
                .then(resp => {

                    if (resp?.card) {

                        const card = resp?.card;

                        const index = paymentMethods.findIndex(x => x.id === card.id);

                        // UPDATE THE MATCHING INDEX IN THE ARRAY
                        const payments = [...paymentMethods];
                        payments[index] = card;

                        dispatch(actionPaymentMethods(payments));
                        setUpdated(true)

                    } else {

                        setError(DEFAULT_TEXT['page.myaccount.tab.paymentMethods.error.base'])
                        setUpdating(false)


                    }

                })


        })

    }, [uid, id, updating, setUpdating, paymentMethods]);

    return (
        <div className='cardRow row'>

            <div className="cardInfo column">

                <div className="card row">

                    <img src={`/img/card-${brand}.svg`} />

                    <div className="cardNumber row">

                        {[...Array(12).keys()].map(x => <div key={x} className='dot' />)}
                        <div className="last4">{last4}</div>

                    </div>

                </div>

                <div className="expiry row">

                    <div className="text">{DEFAULT_TEXT['page.myaccount.tab.paymentMethods.label.expiry']}</div>

                    <div className="inputBox row">

                        <select name="exp_month" defaultValue={exp_month} ref={expireMonthRef}>
                            {months.map(month =>
                                <option
                                    key={month}
                                    value={month}
                                >
                                    {month}
                                </option>
                            )}
                        </select>

                        <div className="separator">/</div>

                        <select name="exp_year" defaultValue={exp_year} ref={expireYearRef}>
                            {years.map(year =>
                                <option
                                    key={year}
                                    value={year}
                                >
                                    {year}
                                </option>
                            )}
                        </select>

                    </div>

                </div>

                <div className="zipCode row">

                    <div className="text">{DEFAULT_TEXT['page.myaccount.tab.paymentMethods.label.zipCode']}</div>

                    <div className="inputBox row">

                        <input type="text" name="zipCode" defaultValue={zipCode} ref={zipCodeRef} maxLength={5} onChange={(e) => e.target.value = e.target.value.replace(/\D/g, "")} />

                    </div>

                </div>

                {error && <div className='error' dangerouslySetInnerHTML={{ __html: error }}></div>}

                <div className="smallBtns row">

                    <div className="smallBtn" onClick={updateCard}>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={updating ? 'show' : ''}>
                            <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z" />
                        </svg>

                        <span className={updating ? 'hide' : ''}>{DEFAULT_TEXT['button.myaccount.tab.paymentMethods.updateCard']}</span>
                    </div>

                    <div className="smallBtn" onClick={removeCard}>{DEFAULT_TEXT['button.myaccount.tab.paymentMethods.remove']}</div>

                </div>

            </div>

        </div>
    );
})

export default Card;