import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';

const PurchasedBids = React.memo(() => {

    const bidsPurchased = useSelector((state) => state.user.bidsPurchased)
    const creditsPackages = useSelector((state) => state.auctions.creditsPackages)

    const purchasedPackage = creditsPackages[bidsPurchased];

    const amount = purchasedPackage.amount;
    const SKU = purchasedPackage.SKU;

    const ISO_CODE = process.env.REACT_APP_ISO_CODE;
    const LANGUAGE = process.env.REACT_APP_LANGUAGE.toUpperCase();

    const filename = `${LANGUAGE}_${SKU}.jpg`
    const filepath = `/img/products/${ISO_CODE}/large/${filename}`

    return (
        <div className='purchasedBox column'>
            <img src={filepath} alt={`${amount} ${DEFAULT_TEXT['member.pop.afterPurchase.bids']}`} />
            <p><span>{amount}</span> {DEFAULT_TEXT['member.pop.afterPurchase.bids']}</p>
        </div>
    );
})

export default PurchasedBids;