import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionMemberPop } from '../../reducers/user'

const HeaderCredits = React.memo(() => {

    const dispatch = useDispatch();
    const credits = useSelector((state) => state.user.credits)

    const openAddCredits = useCallback(() => {

        dispatch(actionMemberPop('addCredits'));

    }, []);

    return (

        <div className="credits row" onClick={openAddCredits}>
            <img src='/img/credit.svg' />
            <span>{credits}</span>
        </div>
    );
})

export default HeaderCredits;