import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import AllWrap from '../components/AllWrap';
import Homepage from '../components/Homepage';
import Privacy from '../components/legal/Privacy';
import Terms from '../components/legal/Terms';
import Signup from '../components/signup/Signup';
import Sold from '../components/Sold';
import TextProvider from '../providers/Text';
import MemberPopProvider from '../providers/MemberPopProvider';
import { PagesProvider } from '../providers/PagesProvider';
import AuthPublic from './AuthPublic';
import CheckAuth from './CheckAuth';
import MyAccount from '../components/MyAccount';
import Help from '../components/Help';
import AuctionPage from '../components/AuctionPage';

const AppRouter = React.memo(() => {
    return (
        <TextProvider>

            <BrowserRouter>
                <MemberPopProvider>
                    <Routes>
                        <Route path="/" element={
                            <CheckAuth>
                                <AllWrap>
                                    <PagesProvider>
                                        <Homepage />
                                    </PagesProvider>
                                </AllWrap>
                            </CheckAuth>
                        } />
                        <Route path="/auctions/:id" element={
                            <CheckAuth>
                                <AllWrap auctionPage={true}>
                                    <AuctionPage />
                                </AllWrap>
                            </CheckAuth>
                        } />
                        <Route path="/sold" element={
                            <CheckAuth>
                                <AllWrap soldPage={true}>
                                    <Sold />
                                </AllWrap>
                            </CheckAuth>
                        } />
                        <Route path="/signup" element={
                            <AuthPublic>
                                <Signup page='signup' />
                            </AuthPublic>
                        } />
                        <Route path="/signin" element={
                            <AuthPublic>
                                <Signup page='signin' />
                            </AuthPublic>
                        } />
                        <Route path="/forgot-password" element={
                            <AuthPublic>
                                <Signup page='forgotPassword' />
                            </AuthPublic>
                        } />
                        <Route path="/reset-password" element={
                            <AuthPublic>
                                <Signup page='resetPassword' />
                            </AuthPublic>
                        } />
                        <Route path="/myaccount" element={
                            <CheckAuth>
                                <AllWrap>
                                    <MyAccount />
                                </AllWrap>
                            </CheckAuth>
                        } >
                        </Route>
                        <Route path="/help" element={
                            <CheckAuth>
                                <AllWrap helpPage={true}>
                                    <Help />
                                </AllWrap>
                            </CheckAuth>
                        } />
                        <Route path="/terms" element={
                            <CheckAuth>
                                <AllWrap>
                                    <Terms />
                                </AllWrap>
                            </CheckAuth>
                        } />
                        <Route path="/privacy" element={
                            <CheckAuth>
                                <AllWrap>
                                    <Privacy />
                                </AllWrap>
                            </CheckAuth>
                        } />
                    </Routes>
                </MemberPopProvider>
            </BrowserRouter>

        </TextProvider>
    );
})

export default AppRouter;