import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { actionSetAuctions, actionAuctionsBids, actionCountdowns, actionJustSold } from '../reducers/auctions';
import { actionCredits, actionUserLoaded, actionUserNotFound } from '../reducers/user'
import { store } from '..';

const socketPort = process.env.REACT_APP_URL_SOCKET
export const socket = io(socketPort);

const Sockets = React.memo(() => {

    const dispatch = useDispatch();

    useEffect(async () => {

        let response = await fetch(process.env.REACT_APP_URL_AUCTIONS)
        const auctions = await response.json()

        dispatch(actionSetAuctions(auctions))

    }, []);

    useEffect(() => {

        socket.on("countdowns", ({ countdowns, soldAuctionIds }) => {

            dispatch(actionCountdowns({ countdowns, soldAuctionIds }))

            if (soldAuctionIds.length) {

                // ADD 1 SECOND DELAY AFTER SELLING FOR TIMER TO HIT 0 THEN ENDED/SOLD SIGN
                setTimeout(() => {
                    dispatch(actionJustSold(soldAuctionIds))
                }, 1000)

            }

        });

        socket.on("actionCredits", (credits) => {

            dispatch(actionCredits(credits))

        });

        socket.on("auctionBids", (bidAuctions) => {

            dispatch(actionAuctionsBids(bidAuctions))

        });

    }, []);

    return null
})

export default Sockets;