import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';

import { actionCloseMenus } from '../../reducers/layout';
import MenuTab from './MenuTab';

export const headerMenu = [
    {
        name: DEFAULT_TEXT['url.signin.text'],
        mobileOnly: true,
        url: DEFAULT_TEXT['url.signin'],
        signedIn: true
    },
    {
        name: DEFAULT_TEXT['header.tab.deals'],
        dealsTab: true,
        cartMode: true
    }, {
        name: DEFAULT_TEXT['url.sold.text'],
        url: DEFAULT_TEXT['url.sold']
    }, {
        name: DEFAULT_TEXT['url.howItWorks.text'],
        url: DEFAULT_TEXT['url.howItWorks']
    }, {
        name: DEFAULT_TEXT['url.help.text'],
        url: DEFAULT_TEXT['url.help'],
        cartMode: true
    }
]

const Menu = React.memo(() => {

    const dispatch = useDispatch();

    const menuOpen = useSelector((state) => state.layout.menuOpen)

    const closeMenu = useCallback(() => {

        dispatch(actionCloseMenus({ menu: true }))

    }, []);

    const menuClickCover = useCallback((e) => {
        // stop document click menu from closing
        e.stopPropagation();
    }, []);

    useEffect(() => {

        const removeEventListener = () => {
            window.removeEventListener("click", closeMenu)
        }

        removeEventListener()

        if (!menuOpen) {

            dispatch(actionCloseMenus({ dealDropdown: true }))

        } else {

            window.addEventListener("click", closeMenu)
        }

        return removeEventListener

    }, [menuOpen]);

    return (
        <ul className={`menuLeft row ${menuOpen ? 'active' : ''}`}>

            {headerMenu.map((x, i) => <MenuTab key={i} {...x} />)}

            <div className="menuClickCover" onClick={menuClickCover}></div>

        </ul>
    );
})

export default Menu;