import React, { useState, useCallback, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import LdsRing from '../snippet/LdsRing';
import { DEFAULT_TEXT } from '../../content';
import { MY_ACCOUNT_TAB_KEYS } from '../MyAccount';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_PUBLIC);

const AddPaymentMethodProvider = React.memo(({ setupIntentSecret, setSetupIntentSecret }) => {

    let appearance = {

        variables: {},
        rules: {
            '.TermsText': {
                fontSize: '10px',
                padding: '0',
                lineHeight: '10px'
            }
        }

    };

    const windowHeight = window.innerHeight;

    if (window.innerWidth < 768) {

        if (windowHeight < 700) {
            appearance.rules['.Label'] = {
                fontSize: '13px'
            }
            appearance.rules['.Input'] = {
                fontSize: '15px',
                paddingTop: '10px',
                paddingBottom: '10px'
            }
            appearance.rules['.Error'] = {
                fontSize: '12px',
            }
        }

        if (windowHeight < 628) {
            appearance.rules['.Input'] = {
                fontSize: '13px',
                paddingTop: '7px',
                paddingBottom: '7px'
            }
        }

    }

    return (
        <Elements stripe={stripePromise} options={{ clientSecret: setupIntentSecret.client_secret, appearance }}>
            <AddPaymentMethod setupIntentSecret={setupIntentSecret} setSetupIntentSecret={setSetupIntentSecret} />
        </Elements>
    );
})

const AddPaymentMethod = React.memo(({ setupIntentSecret, setSetupIntentSecret }) => {

    const uid = useSelector((state) => state.user.uid)

    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState(false);

    const [fadeInCheckout, setFadeInCheckout] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = useCallback((event) => {

        event.preventDefault();

        if (!stripe || !elements || loading) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true)

        stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.href}?${MY_ACCOUNT_TAB_KEYS.PAYMENT_METHODS}=true`,
            }
        }).then(({ error }) => {

            setLoading(false)

            if (error.type === "card_error") {

                setMessage(error.message);

            }

        })


    }, [uid, stripe, elements]);

    useEffect(() => {

        if (setupIntentSecret.stripeReady) {

            // FADE IN CHECKOUT FOR SMOOTHER TRANSITION
            setFadeInCheckout(true)

        }

    }, [setupIntentSecret.stripeReady]);

    return (
        <form className={`creditCardBox column ${setupIntentSecret.stripeReady ? 'show' : ''} ${fadeInCheckout ? 'opacity' : ''}`} onSubmit={handleSubmit}>

            <PaymentElement onReady={() => setSetupIntentSecret(x => ({
                ...x,
                loading: false,
                stripeReady: true
            }))
            } />

            {message && <div className="error">{message}</div>}

            <div className="buttonBox column">
                <button>

                    {loading ?
                        <LdsRing />
                        :
                        DEFAULT_TEXT['button.myaccount.tab.paymentMethods.addPaymentMethod']
                    }

                </button>
            </div>

        </form>
    );
})

export default AddPaymentMethodProvider;