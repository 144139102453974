import React, { useLayoutEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { actionUserDoesNotExist, actionUserLoaded, actionUserNotFound } from '../reducers/user'
import { useDispatch } from 'react-redux';
import { scrollTop } from '../components/helpers';

const CheckAuth = React.memo(({ children }) => {

    const dispatch = useDispatch();

    // AUTH STATE CHANGE
    useLayoutEffect(() => {
        const auth = getAuth();

        onAuthStateChanged(auth, (user) => {

            const uid = user?.uid;

            if (uid) {

                // UID AND CREDITS WILL BE POPULATED IN sockets.js with the return

                fetch(`${process.env.REACT_APP_URL_GET_USER}?uid=${uid}`)
                    .then(response => response.json())
                    .then((user) => {

                        if (user) {

                            if (user?.userNotFoundError) {
                                throw 'User does not exist'
                            }

                            scrollTop();

                            dispatch(actionUserLoaded(user))

                        }

                    }).catch(e => {

                        dispatch(actionUserNotFound(true))

                    })

            } else {

                dispatch(actionUserDoesNotExist())

            }
        });

    }, []);

    return children;
})

export default CheckAuth;