import React, { useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { MemberPopContext } from '../MemberPop';

const Card = React.memo(({ count, brand, last4, addNewCard }) => {

    const { cardSelected, setCardSelected } = useContext(MemberPopContext)

    const onClick = useCallback(() => {

        setCardSelected(count)

    }, [setCardSelected]);

    return (
        <div className={`card row ${count === cardSelected ? 'selected' : ''}`} onClick={onClick}>

            <div className="left row">

                <div className='radio row'>
                    <div className="circle"></div>
                </div>

                {!addNewCard ?
                    <>
                        <img src={`/img/card-${brand}.svg`} />

                        <div className="cardNumber row">

                            {[...Array(12).keys()].map(x => <div key={x} className='dot' />)}
                            <div className="last4">{last4}</div>

                        </div>
                    </>

                    :
                    DEFAULT_TEXT['member.pop.selectCreditCard.addNew']
                }

            </div>

        </div>
    );
})

const CardListing = React.memo(() => {

    const { refillError } = useContext(MemberPopContext)

    const paymentMethods = useSelector((state) => state.user.paymentMethods)

    return (
        <div className='cardListing column'>

            {paymentMethods.map((x, i) => <Card key={i} {...x} count={i} />)}
            <Card addNewCard={true} count={paymentMethods.length} />

            {refillError && <div className='error'>{DEFAULT_TEXT['member.pop.selectCreditCard.addNewError']}</div>}

        </div>
    );
})

export default CardListing;