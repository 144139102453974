import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAuth, signOut } from "firebase/auth";
import { actionBidsPurchased, actionMemberPop, actionPaymentMethods, actionUserNotFound } from '../reducers/user';
import { getAccessToken, getParams } from '../components/helpers';
import MemberPop from '../components/MemberPop';
import { socket } from '../components/Sockets';

const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'resize'];

export const MemberPopProvider = React.memo(({ children }) => {

    const userTimeout = useRef(null)

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { userLoaded, userNotFound, uid, memberPop, avatar } = useSelector((state) => state.user)

    const creditsPackages = useSelector((state) => state.auctions.creditsPackages)
    const paymentMethods = useSelector((state) => state.user.paymentMethods)

    // LOAD AVATAR POP FOR MEMBERS WHO HAVE NOT SELECTED AN AVATAR
    useLayoutEffect(() => {

        if (uid && userLoaded && !avatar) {
            dispatch(actionMemberPop('avatars'));
        }

    }, [uid, userLoaded, avatar]);

    // SIGNOUT USERS WHO ARE NOT FOUND
    useEffect(() => {

        if (userNotFound) {
            //USER IS LOGGED IN AND NOT FOUND, CAN BE FROM BEING DELETED
            dispatch(actionUserNotFound(false))
            const auth = getAuth();
            signOut(auth)
        }

    }, [userNotFound]);

    // AFTER PURCHASE
    useEffect(() => {

        if (!userLoaded || !creditsPackages) {
            return
        }

        const { redirect_status, bidPackage } = getParams();

        if ((bidPackage >= 0) && (redirect_status === 'succeeded')) {
            navigate(window.location.pathname, { replace: true });
            // // THIS WILL LAUNCH THE POPUP AND ALSO FILL IN THE BID PACKAGE IN REDUX
            dispatch(actionBidsPurchased(bidPackage));
        }

    }, [userLoaded, creditsPackages]);

    useEffect(() => {


        if (uid && userLoaded && !paymentMethods?.length) {

            getAccessToken().then(token => {

                return fetch(process.env.REACT_APP_URL_PAYMENT_METHODS, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ accessToken: token, uid }),
                })
                    .then(response => response.json())
                    .then(payments => {

                        if (payments) {

                            dispatch(actionPaymentMethods(payments));

                        }

                    })

            })

        }

    }, [uid, userLoaded, paymentMethods]);

    const moved = useCallback(() => {

        const removeEventListener = () => {
            events.forEach((name) => {
                document.removeEventListener(name, resetTimer, true);
            });
        }

        removeEventListener()

        resetTimer();

        return removeEventListener

    }, []);

    const resetTimer = useCallback(() => {

        events.forEach((name) => {
            document.addEventListener(name, moved, true);
        });

        clearTimeout(userTimeout.current)

        userTimeout.current = setTimeout(() => {

            dispatch(actionMemberPop('idleBox'));

            const body = document.querySelector('body');
            body.style.overflow = 'hidden';

            socket.disconnect()

        }, 1000 * 60 * 5)

    }, [socket]);

    useEffect(() => {
        resetTimer()
    }, []);


    return (
        <>
            {children}

            {(memberPop === 'idleBox') || (uid && memberPop) || (memberPop === 'cartModeShipping') || (memberPop === 'cartBilling') ?
                <MemberPop />
                :
                ''
            }


        </>
    );
})

export default MemberPopProvider;
