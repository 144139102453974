import React, { useCallback, useContext, useState } from 'react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { MemberPopContext } from '../MemberPop';
import { CURRENCY } from '../../content/currency';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_PUBLIC);

const StripeProvider = React.memo(({ children }) => {

    const { paymentIntent } = useContext(MemberPopContext)

    let appearance = {

        variables: {},
        rules: {
            '.TermsText': {
                fontSize: '10px',
                padding: '0',
                lineHeight: '10px'
            }
        }

    };

    const windowHeight = window.innerHeight;

    if (window.innerWidth < 768) {

        if (windowHeight < 700) {
            appearance.rules['.Label'] = {
                fontSize: '13px'
            }
            appearance.rules['.Input'] = {
                fontSize: '15px',
                paddingTop: '10px',
                paddingBottom: '10px'
            }
            appearance.rules['.Error'] = {
                fontSize: '12px',
            }
        }

        if (windowHeight < 628) {
            appearance.rules['.Input'] = {
                fontSize: '13px',
                paddingTop: '7px',
                paddingBottom: '7px'
            }
        }

    }

    return (
        <>
            <Elements stripe={stripePromise} options={{ clientSecret: paymentIntent, appearance }}>
                <Stripe />
            </Elements>
        </>
    );
})

const Stripe = React.memo(() => {

    const { bidPackage, step, submitButtonRef, loading, setLoading, setStripeLoaded } = useContext(MemberPopContext)

    const uid = useSelector((state) => state.user.uid)
    const creditsPackages = useSelector((state) => state.auctions.creditsPackages)

    const [message, setMessage] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = useCallback((event) => {

        event.preventDefault();

        if (!stripe || !elements || loading) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true)

        stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.href}?bidPackage=${bidPackage}`,
            },

        }).then(({ error }) => {

            setLoading(false)

            if (error.type === "card_error") {

                setMessage(error.message);

            }

        });


    }, [uid, stripe, elements, bidPackage, loading]);


    return (
        <>
            {elements &&

                <form className={`creditCardBox column ${(step === 'addCreditCard') || (step === 'cart') ? 'active' : ''}`} onSubmit={handleSubmit}>

                    {(bidPackage >= 0 && step !== 'cart') ?

                        <div className="checkout row">

                            <div className="checkoutLeft row">

                                <div className="radio row">
                                    <div className="circle"></div>
                                </div>

                                <div className="amount row">
                                    <span>{creditsPackages[bidPackage]?.amount}</span>
                                    <div className="sub">{DEFAULT_TEXT['member.pop.bidSelect.bids']}</div>
                                </div>

                            </div>

                            <div className="cost">
                                {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{creditsPackages[bidPackage]?.cost}
                            </div>

                        </div>

                        :
                        ''

                    }

                    <PaymentElement onReady={() => setStripeLoaded(true)} />

                    {message && <div className="error">{message}</div>}

                    <button ref={submitButtonRef}>{DEFAULT_TEXT['member.pop.button.submit']}</button>

                </form>
            }
        </>
    );
})

export default StripeProvider;