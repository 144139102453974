//DESCRIPTION ONLY USED TO REMEMBER ICON
export const signupSvgs = [
    {
        key: `top`,
        svg: ` <path d="M12,6.019l1.618,3.279l0.465,0.943l1.041,0.151l3.618,0.526l-2.618,2.552l-0.753,0.734l0.178,1.036l0.618,3.604 l-3.236-1.701L12,16.653l-0.931,0.489l-3.236,1.701l0.618-3.604l0.178-1.036L7.876,13.47l-2.618-2.552l3.618-0.526l1.041-0.151 l0.465-0.943L12,6.019 M12,1.5L8.588,8.413L0.96,9.521l5.52,5.381L5.177,22.5L12,18.913l6.823,3.587l-1.303-7.598l5.52-5.381 l-7.629-1.109L12,1.5L12,1.5z"/>`
    },
    {
        description: `shipping`,
        svg: `<path d="M 0 5 L 0 7 L 14 7 L 14 11 L 14 13 L 14 16 L 8.2207031 16 C 7.6716274 15.390667 6.8849156 15 6 15 C 5.1150844 15 4.3283726 15.390667 3.7792969 16 L 3 16 L 3 15 L 5 13 L 1 13 L 1 18 L 3 18 C 3 19.657 4.343 21 6 21 C 7.657 21 9 19.657 9 18 L 15 18 C 15 19.657 16.343 21 18 21 C 19.657 21 21 19.657 21 18 L 22 18 L 23 18 L 23 13 L 20.275391 7.5527344 C 20.106391 7.2137344 19.761813 7 19.382812 7 L 16 7 L 16 5 L 0 5 z M 1 9 L 1 11 L 8 11 L 8 9 L 1 9 z M 16 9 L 18.763672 9 L 19.763672 11 L 16 11 L 16 9 z M 16 13 L 20.763672 13 L 21 13.472656 L 21 16 L 20.220703 16 C 19.671627 15.390667 18.884916 15 18 15 C 17.227916 15 16.53164 15.300231 16 15.779297 L 16 13 z M 6 16.75 C 6.689 16.75 7.25 17.311 7.25 18 C 7.25 18.689 6.689 19.25 6 19.25 C 5.311 19.25 4.75 18.689 4.75 18 C 4.75 17.311 5.311 16.75 6 16.75 z M 18 16.75 C 18.689 16.75 19.25 17.311 19.25 18 C 19.25 18.689 18.689 19.25 18 19.25 C 17.311 19.25 16.75 18.689 16.75 18 C 16.75 17.311 17.311 16.75 18 16.75 z"/>`
    },
    {
        description: `check`,
        svg: `<path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 16.292969 8.2929688 L 10 14.585938 L 7.7070312 12.292969 L 6.2929688 13.707031 L 10 17.414062 L 17.707031 9.7070312 L 16.292969 8.2929688 z"/>`
    },
    {
        description: `eye`,
        svg: `<path d="M 12 4 C 4 4 1 12 1 12 C 1 12 4 20 12 20 C 20 20 23 12 23 12 C 23 12 20 4 12 4 z M 12 6 C 17.276 6 19.944594 10.267094 20.808594 11.996094 C 19.943594 13.713094 17.255 18 12 18 C 6.724 18 4.0554062 13.732906 3.1914062 12.003906 C 4.0574062 10.286906 6.745 6 12 6 z M 12 8 C 9.791 8 8 9.791 8 12 C 8 14.209 9.791 16 12 16 C 14.209 16 16 14.209 16 12 C 16 9.791 14.209 8 12 8 z M 12 10 C 13.105 10 14 10.895 14 12 C 14 13.105 13.105 14 12 14 C 10.895 14 10 13.105 10 12 C 10 10.895 10.895 10 12 10 z"/>`
    },
    {
        description: `box`,
        svg: `<path d="M 5.75 3 A 1.0001 1.0001 0 0 0 4.8867188 3.4960938 L 3.1367188 6.4960938 A 1.0001 1.0001 0 0 0 3 7 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 7 A 1.0001 1.0001 0 0 0 20.863281 6.4960938 L 19.113281 3.4960938 A 1.0001 1.0001 0 0 0 18.25 3 L 5.75 3 z M 6.3242188 5 L 17.675781 5 L 18.841797 7 L 5.1582031 7 L 6.3242188 5 z M 5 9 L 19 9 L 19 19 L 5 19 L 5 9 z M 9 11 L 9 13 L 15 13 L 15 11 L 9 11 z" />`
    },
    {
        description: `tag`,
        svg: `<path d="M 12.171875 3 L 2.5859375 12.585938 C 2.2079375 12.963938 2 13.466 2 14 C 2 14.534 2.2079375 15.036062 2.5859375 15.414062 L 8.5859375 21.414062 C 8.9639375 21.792063 9.466 22 10 22 C 10.534 22 11.036062 21.792063 11.414062 21.414062 L 21 11.828125 L 21 3 L 12.171875 3 z M 13 5 L 19 5 L 19 11 L 10 20 L 4 14 L 13 5 z M 16 7 A 1 1 0 0 0 15 8 A 1 1 0 0 0 16 9 A 1 1 0 0 0 17 8 A 1 1 0 0 0 16 7 z"/>`
    }
]