import { DEFAULT_TEXT } from "../../../content";

export const submitSignup = async (obj, page) => {

    try {

        let signup = await fetch(process.env.REACT_APP_URL_SIGN_UP, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj),
        })

        signup = await signup.json()

        if (signup?.success) {

            return { success: true }

        } else {

            // error on signup
            throw signup

        }

    } catch (error) {

        let message = DEFAULT_TEXT[`form.baseError.whoops.${page}`]

        if (error?.code === 'auth/email-already-exists') {

            message = DEFAULT_TEXT[`form.baseError.inUse`]

        }

        return message
    }

}