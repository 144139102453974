import React, { useEffect, useState } from 'react';
import { SoldPageTitleBox, Auctions } from '../style/main';
import { DEFAULT_TEXT } from '../content';
import AuctionSingle from './AuctionSingle';

const Sold = React.memo(() => {

    const [soldAuctions, setSoldauctions] = useState([]);

    useEffect(async () => {

        if (!soldAuctions?.length) {

            let sold = await fetch(process.env.REACT_APP_URL_SOLD)
            sold = await sold.json()

            setSoldauctions(sold);

        }

    }, [soldAuctions]);

    return (
        <>

            <div className='wrap column'>
                <SoldPageTitleBox className='column'>
                    <h1>{DEFAULT_TEXT['page.sold.title']}</h1>
                    <div className="blurb">{DEFAULT_TEXT['page.sold.blurb']}</div>
                </SoldPageTitleBox>


                <Auctions className='row'>
                    {!soldAuctions?.length ?
                        [...Array(9).keys()].map(x => <AuctionSingle key={x} loading={true} />)
                        :
                        soldAuctions.map((x, i) => <AuctionSingle key={i} {...x} soldPage={true} />)
                    }
                </Auctions>
            </div>

        </>
    );
})

export default Sold;